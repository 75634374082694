import { combineReducers } from 'redux';

import user from './user';
import files from './files';
import alerts from './alerts';
import viewings from './viewings';
import offers from './offers';
import notifications from './notifications';
import misc from './misc';
import agent from './agent';
import rightmove from './rightmove';
import properties from './properties';
import summary from './summary';
import chat from './chat';
import sellerAvailability from './sellerAvailability';
import orders from './orders';
import mip from './mip';
import zoho from './zoho';

const rootReducer = combineReducers({
  properties,
  summary,
  user,
  files,
  alerts,
  viewings,
  offers,
  notifications,
  misc,
  agent,
  rightmove,
  chat,
  sellerAvailability,
  orders,
  mip,
  zoho,
});

export default rootReducer;
