import { useSelector } from 'react-redux';

export const useUserSelector = () => {
  const user = useSelector((state) => state.user);

  return user;
};

export const useIsVendor = (userId) => {
  const userObject = useUserSelector();

  if (parseInt(userObject?.user?.id, 10) !== parseInt(userId, 10)) {
    return true;
  }

  return false;
};

export const useFinancialPosition = () => {
  const { financialPosition } = useUserSelector();

  return financialPosition;
};
