import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from '@strikelabs/luna';

const Placeholder = () => (
  <>
    <Box mb={1}>
      <ContentLoader viewBox="0 0 668 100" width="100%">
        <circle cx="50" cy="50" r="50" />
        <rect x="120" y="0" rx="10" ry="10" width="82%" height="60" />
        <rect x="120" y="70" rx="10" ry="10" width="30%" height="30" />
      </ContentLoader>
    </Box>
    <Box mb={1}>
      <ContentLoader viewBox="0 0 668 100" width="100%">
        <circle cx="50" cy="50" r="50" />
        <rect x="120" y="0" rx="10" ry="10" width="82%" height="60" />
        <rect x="120" y="70" rx="10" ry="10" width="30%" height="30" />
      </ContentLoader>
    </Box>
    <Box>
      <ContentLoader viewBox="0 0 668 100" width="100%">
        <circle cx="50" cy="50" r="50" />
        <rect x="120" y="0" rx="10" ry="10" width="82%" height="60" />
        <rect x="120" y="70" rx="10" ry="10" width="30%" height="30" />
      </ContentLoader>
    </Box>
  </>
);

export default Placeholder;
