import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Urls/partial urls we dont want to scroll to top on
const blacklistUrls = ['details/edit'];

const canScroll = (pathname) => {
  const isBlacklistedUrl = blacklistUrls.find((url) => pathname.includes(url));

  return isBlacklistedUrl ? false : true;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const canScrollToTop = canScroll(pathname);

    if (canScrollToTop) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [pathname]);

  return null;
};
export default ScrollToTop;
