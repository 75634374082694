import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

export const useFilesSelector = () => {
  const files = useSelector((state) => state.files);

  return files;
};

export const useFilesSelectorByCategory = ({ category, propertyId }) => {
  const files = useFilesSelector();

  if (isEmpty(files[category]) || isEmpty(files[category][propertyId])) {
    return [];
  }

  return files[category][propertyId];
};
