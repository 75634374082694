import uniqueId from 'lodash/uniqueId';

export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT: {
      const { payload } = action;
      return [
        ...state,
        {
          id: uniqueId('alert_'),
          ...payload,
        },
      ];
    }
    case REMOVE_ALERT: {
      const { id } = action;
      return state.filter((alert) => alert.id !== id);
    }

    default:
      return state;
  }
};

export default reducer;
