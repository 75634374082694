import { transformViewingPayload } from '@strikelabs/vega';
import { arrayToObject, keyObject } from '@strikelabs/pax';

import { PURGE } from 'constants/actions';

export const FETCHED_VIEWINGS = 'FETCHED_VIEWINGS';
export const FETCHED_VIEWING = 'FETCHED_VIEWING';
export const VIEWING_CANCELLED = 'VIEWING_CANCELLED';
export const VIEWING_CONFIRMED = 'VIEWING_CONFIRMED';
export const VIEWING_CREATED = 'VIEWING_CREATED';
export const VIEWING_RESCHEDULED = 'VIEWING_RESCHEDULED';
export const VIEWING_COMPLETED = 'VIEWING_COMPLETED';

export const initialState = {
  loaded: false,
  list: {},
};

const addViewing = ({ data }, list) => {
  const tempViewing = transformViewingPayload(data);
  return Object.assign({}, list, keyObject(tempViewing));
};

const addViewingsList = ({ data }) => {
  const { viewings } = data;
  if (!viewings) {
    return {};
  }

  const tempPayload = viewings.map((viewing) => {
    return transformViewingPayload(viewing);
  });

  return arrayToObject(tempPayload);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_VIEWINGS: {
      const { payload } = action;
      return {
        loaded: true,
        list: addViewingsList(payload),
      };
    }
    case FETCHED_VIEWING:
    case VIEWING_CREATED:
    case VIEWING_CANCELLED:
    case VIEWING_COMPLETED:
    case VIEWING_RESCHEDULED:
    case VIEWING_CONFIRMED: {
      const { payload } = action;
      return {
        ...state,
        list: addViewing(payload, state.list),
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
