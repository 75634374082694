import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 20px;
`;

export const Badge = styled.div`
  min-width: 18px;
  height: 18px;
  padding: 2px 4px;
  border-radius: 10px;
  background-color: #ff0054;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  &:over {
    cursor: pointer;
  }
`;

export const BadgeText = styled.span`
  font-size: 9px;
  line-height: 14px;
  display: block;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
`;

export const Outer = styled.div`
  position: relative;
  cursor: pointer;
`;
