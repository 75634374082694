import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { identifier } from '@strikelabs/pax';
import { Flex, Icon } from '@strikelabs/luna';

import {
  Nav,
  IconText,
  MenuLink,
  MenuLinkInner,
  IconContainer,
  Badge,
} from './style';

const MobileNavigation = ({ menu }) => {
  const menuItems = useMemo(() => {
    return menu.map((menuItem) => {
      return (
        <MenuLink
          key={menuItem.selector}
          exact={menuItem.title === 'Dashboard'}
          {...identifier({ name: 'menu', id: menuItem.selector })}
          to={menuItem.route}
        >
          <Badge small background="statusError" color="white">
            {menuItem.badge}
          </Badge>
          <MenuLinkInner>
            <IconContainer>
              <Icon
                type={menuItem.icon}
                width={menuItem.mobileIconWidth}
                height={menuItem.mobileIconHeight}
              />
            </IconContainer>
            <IconText pb={0}>{menuItem.title}</IconText>
          </MenuLinkInner>
        </MenuLink>
      );
    });
  }, [menu]);
  return (
    <Nav>
      <Flex
        width="100%"
        height="100%"
        justifyContent="space-around"
        alignItems="flex-end"
      >
        {menuItems}
      </Flex>
    </Nav>
  );
};

MobileNavigation.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
};

export default memo(MobileNavigation);
