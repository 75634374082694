import styled from 'styled-components';

export const GroupTop = styled.div`
  margin-bottom: ${({ margin }) => `${margin}px`};

  ${({ theme }) => theme.media.tablet} {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const GroupBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.media.smallTablet} {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
  }

  ${({ theme }) => theme.media.desktop} {
    margin: 0 auto;
    width: 100%;
    max-width: 1350px;
  }
`;

export const Body = styled.div`
  ${({ theme }) => theme.media.desktop} {
    padding: 0 ${({ theme }) => theme.spacing.sm};
    display: grid;
    grid-template-columns: minmax(200px, 250px) minmax(0, 1fr);
  }
`;
