import { PURGE } from 'constants/actions';
import { DEFAULT_AGENT } from 'constants/agent';
export const FETCH_AGENTS = 'FETCH_AGENTS';

export const initialState = {
  [DEFAULT_AGENT]: {
    default: true,
    email: 'info@strike.co.uk',
    phone: '0333 103 8390',
    phone_number: '0333 103 8390',
  },
  selling: {},
  buying: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AGENTS: {
      const { payload } = action;

      if (payload.activeMode === 'selling') {
        return {
          ...state,
          selling: {
            ...state.selling,
            [payload.id]: payload.data,
          },
        };
      }
      if (payload.activeMode === 'buying') {
        return {
          ...state,
          buying: {
            ...payload.data,
          },
        };
      }
      break;
    }
    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
