import { createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import rootReducer from 'reducers';

// Redux: Store
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware()));

// Exports
export { store };
