import { PURGE } from 'constants/actions';

export const FETCHED_SELLER_AVAILABILITY = 'FETCHED_SELLER_AVAILABILITY';

export const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_SELLER_AVAILABILITY: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: payload.data,
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
