import React, { useMemo, useEffect } from 'react';
import loadable from '@loadable/component';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import componentLoader from 'utils/componentLoader';
import BaseLayout from 'components/v2/Layout/BaseLayout';

import useNotifications from 'hooks/useNotifications';
import useFirebaseServiceWorker from 'hooks/useSWReg';
import useModeControl from 'hooks/utils/useModeControl';

const Root = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/Root'))
);

const Buying = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/_buying'))
);

const Selling = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/_selling'))
);

const MoveReady = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/MoveReady'))
);

const SavedProperties = loadable(() =>
  componentLoader(() =>
    import(/* webpackPrefetch: true */ 'pages/SavedProperties')
  )
);

const Messages = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/MessagesV2'))
);

const Profile = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/Profile'))
);

const Notifications = loadable(() =>
  componentLoader(() =>
    import(/* webpackPrefetch: true */ 'pages/Notifications')
  )
);

const Legal = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/Legal'))
);

const Content = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/Content'))
);

const NotFound = loadable(() =>
  componentLoader(() => import(/* webpackPrefetch: true */ 'pages/NotFound'))
);

const PerformanceRedirect = () => {
  const { setActiveProperty } = useModeControl();
  const { propertyId } = useParams();

  /*
   * This path is no handled on the ModeProvider
   * because of that it doesn't understand what active proeprty
   * should be.
   *
   * We want to set that before redirect happens
   */
  useEffect(() => {
    setActiveProperty(propertyId);
  }, []);

  return <Redirect to={{ pathname: `/selling/${propertyId}/performance` }} />;
};

export const routes = [
  {
    path: '/',
    exact: true,
    main: Root,
  },
  {
    path: '/offers',
    main: Root,
  },
  {
    path: '/buying',
    main: Buying,
  },
  {
    path: '/selling',
    main: Selling,
  },
  {
    path: '/viewings',
    main: Root,
  },
  {
    path: '/property/:propertyId/performance',
    main: PerformanceRedirect,
  },
  {
    path: '/move-ready',
    main: MoveReady,
  },
  {
    path: '/saved-properties',
    main: SavedProperties,
  },
  {
    path: '/messages',
    main: Messages,
  },
  {
    path: '/profile',
    main: Profile,
  },
  {
    path: '/notifications',
    main: Notifications,
  },
  {
    path: '/legal',
    main: Legal,
  },
  {
    path: '/content',
    main: Content,
  },
  {
    path: null,
    main: NotFound,
  },
];

/*
  * There is something odd with route switch animation when hero and mode switch
  * is present. For some reason it adds transition aboe the children that changes.

  * This is not a critical issue, but something that's worth investigating when we
  * have some more spare time. Regardless it looks fine now.
*/

const Routes = () => {
  /*
   * Fetch information that's required
   * to display correct UI elements
   */
  useNotifications({ autoGetBadge: true });

  if (process.env.FIREBASE_PROJECT_ID) {
    useFirebaseServiceWorker();
  }

  const renderRoutes = useMemo(() => {
    return routes.map((route) => {
      if (!route?.disabled) {
        return (
          <Route key={route.path} path={route.path} exact={route.exact}>
            <route.main />
          </Route>
        );
      }
    }, []);
  });

  return (
    <BaseLayout>
      <Switch>{renderRoutes}</Switch>
    </BaseLayout>
  );
};

export default Routes;
