import { useEffect } from 'react';
import { useToast as useToastLuna } from '@strikelabs/luna';
import { SUCCESS, FAILED, USE_LOGIN } from '@strikelabs/vega';
import ALERTS from 'constants/alerts';

const { pushToast, TOAST_TYPE } = useToastLuna();

const useToast = ({ status, name, error }) => {
  useEffect(() => {
    if (status === SUCCESS && ALERTS[SUCCESS][name]) {
      return pushToast({
        type: TOAST_TYPE.SUCCESS,
        message: ALERTS[SUCCESS][name],
        options: { id: 'success', duration: 5000 },
      });
    }

    if (status === FAILED && ALERTS[FAILED][name]) {
      /*
       * If error code === 403 disable alert and handle it in UI
       */
      if (name === USE_LOGIN.SUBMIT_EMAIL && error?.status === 403) {
        return;
      }

      return pushToast({
        type: TOAST_TYPE.ERROR,
        message: ALERTS[FAILED][name],
        options: { id: 'error', duration: 5000 },
      });
    }
  }, [status]);
};

export default useToast;
