import { PURGE } from 'constants/actions';

export const FETCH_MIP = 'FETCH_MIP';

const initialState = {
  mip: null,
  loaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MIP: {
      const { payload } = action;

      return {
        ...state,
        mip: payload,
        loaded: true,
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
