import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Icon } from '@strikelabs/luna';
import { parseISO, formatDistance } from 'date-fns';

import { Outer, Container, Title, Caption, IconContainer } from './style';

const Notification = ({ title, timestamp, status, onClick, ...rest }) => {
  const timeLabel = useMemo(() => {
    if (!timestamp) {
      return null;
    }

    let [start, end] = timestamp.split(' ');
    let parsed;

    if (end) {
      parsed = new Date(parseISO(`${start} ${end.padStart(8, '0')}`));
    } else {
      parsed = parseISO(timestamp);
    }

    return `${formatDistance(new Date(), parsed)} ago`;
  }, []);

  return (
    <Outer onClick={onClick} {...rest}>
      <Container flexWrap="nowrap" status={status}>
        <Box mr={1}>
          <IconContainer>
            <Icon theme="dark" width={22} type="strikeLogoSmall" />
          </IconContainer>
        </Box>
        <Box flex={1}>
          <Title>{title}</Title>
          <Caption>{timeLabel}</Caption>
        </Box>
      </Container>
    </Outer>
  );
};

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Notification;
