import { useRouteMatch } from 'react-router-dom';

import { usePropertiesArraySelector } from 'selectors/properties';
import { useMiscSelector } from 'selectors/misc';

const useGetActivePropertyId = () => {
  /*
   * We only care about selling mode properties since
   * buying mode is not property specific
   */
  const { activeProperty } = useMiscSelector();
  const properties = usePropertiesArraySelector('selling');
  const propertyMatch = useRouteMatch('/selling/:propertyId');
  const propertyId = propertyMatch?.params?.propertyId;

  // Check if id exists in the property list
  const validateId = (propertyId) =>
    properties.some((property) => Number(property.id) === Number(propertyId));

  const getPropertyId = () => {
    // Take id from URL params
    if (propertyId) {
      return propertyId;
    }

    // Take id from redux that is also defaulted to localstorage
    if (activeProperty) {
      return activeProperty;
    }

    return null;
  };

  return {
    validateId,
    getPropertyId,
  };
};

export default useGetActivePropertyId;
