import {
  USE_OFFERS,
  USE_VIEWINGS,
  USE_HOMEVISIT,
  USE_TIMELINE,
  SUCCESS,
  FAILED,
  USE_LOGIN,
  USE_USER,
  USE_PROPERTY_DETAILS,
  USE_ADVERT,
  USE_FILEMANAGER,
  USE_COMMS_PREFERENCES,
  USE_WATCHED_PROPERTIES,
  USE_SELLER_AVAILABILITY,
} from '@strikelabs/vega';

const GENERIC_ERROR =
  "Sorry, we couldn't complete your request. Please contact us on 0333 103 8390 or email us at info@strike.co.uk if you need anything.";

/* *************************************************** */
/* useOffers                                           */
/* *************************************************** */
export const OFFER_SUCCESS_ALERTS = {
  [USE_OFFERS.OFFERS_FETCH]: null,
  [USE_OFFERS.OFFER_FETCH]: null,
  [USE_OFFERS.OFFER_ACCEPT]:
    "Congratulations, you have accepted this offer. We'll contact you shortly.",
  [USE_OFFERS.OFFER_NEGOTIATE]: "We're on it!",
  [USE_OFFERS.OFFER_REJECT]: "We're on it!",
  [USE_OFFERS.OFFER_DECLINE]: "We're on it!",
  [USE_OFFERS.OFFER_WITHDRAW]: 'Your offer has been withdrawn',
  [USE_OFFERS.OFFER_CREATE]: 'Your offer has been sent, good luck!',
};

export const OFFER_FAILED_ALERTS = {
  [USE_OFFERS.OFFERS_FETCH]: GENERIC_ERROR,
  [USE_OFFERS.OFFER_FETCH]: GENERIC_ERROR,
  [USE_OFFERS.OFFER_ACCEPT]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk and we can accept the offer on your behalf.",
  [USE_OFFERS.OFFER_NEGOTIATE]:
    "Sorry, we're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk for advice.",
  [USE_OFFERS.OFFER_REJECT]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk to do this on your behalf.",
  [USE_OFFERS.OFFER_WITHDRAW]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk to do this on your behalf.",
  [USE_OFFERS.OFFER_CREATE]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. Alternatively, you can still make an offer by phoning us on 0333 103 8390 or email us at info@strike.co.uk",
};

/* *************************************************** */
/* useViewings                                         */
/* *************************************************** */
export const VIEWINGS_SUCCESS_ALERTS = {
  [USE_VIEWINGS.VIEWINGS_FETCH]: null,
  [USE_VIEWINGS.VIEWING_FETCH]: null,
  [USE_VIEWINGS.VIEWING_CANCEL]: 'Your viewing has been cancelled',
  [USE_VIEWINGS.VIEWING_CREATE]: 'Your viewing has been created',
  [USE_VIEWINGS.VIEWING_CONFIRM]: "You've confirmed this viewing.",
  [USE_VIEWINGS.VIEWING_RESCHEDULE]:
    'Your suggested time/s have been sent across',
  [USE_VIEWINGS.VIEWING_COMPLETE]: null,
};

export const VIEWINGS_FAILED_ALERTS = {
  [USE_VIEWINGS.VIEWINGS_FETCH]: GENERIC_ERROR,
  [USE_VIEWINGS.VIEWING_FETCH]: GENERIC_ERROR,
  [USE_VIEWINGS.VIEWING_CANCEL]:
    "Sorry, we couldn't cancel your viewing. Please contact us on 0333 103 8390 or email us at info@strike.co.uk and we can cancel this for you.",
  [USE_VIEWINGS.VIEWING_CREATE]:
    "Sorry, we couldn't create the viewing. Please contact us on 0333 103 8390 or email us at info@strike.co.uk and we can confirm this for you.",
  [USE_VIEWINGS.VIEWING_CONFIRM]:
    "Sorry, we couldn't confirm the viewing. Please contact us on 0333 103 8390 or email us at info@strike.co.uk and we can confirm this for you.",
  [USE_VIEWINGS.VIEWING_RESCHEDULE]:
    "Sorry, we're currently experiencing some technical issues. Speak to us on 0333 103 8390 or email us at info@strike.co.uk and we can arrange this for you.",
  [USE_VIEWINGS.VIEWING_COMPLETE]: GENERIC_ERROR,
};

/* *************************************************** */
/* useHomevisit                                        */
/* *************************************************** */
export const HOMEVISIT_SUCCESS_ALERTS = {
  [USE_HOMEVISIT.HOMEVISIT_CREATE]: 'Your visit is booked in',
  [USE_HOMEVISIT.HOMEVISIT_CANCEL]: 'Your visit has been cancelled',
  [USE_HOMEVISIT.HOMEVISIT_CANCEL_FEEDBACK]: 'Thank you for your feedback',
  [USE_HOMEVISIT.HOMEVISIT_UPDATE]: ' Your visit has been rescheduled',
  [USE_HOMEVISIT.HOMEVISIT_DETAILS]: null,
};

export const HOMEVISIT_FAILED_ALERTS = {
  [USE_HOMEVISIT.HOMEVISIT_CREATE]:
    'Sorry, we cannot book your visit in right now, please contact your Strike Agent to do this for you.',
  [USE_HOMEVISIT.HOMEVISIT_CANCEL]:
    'Sorry, we cannot cancel your visit right now, please contact your Strike Agent to do this for you.',
  [USE_HOMEVISIT.HOMEVISIT_CANCEL_FEEDBACK]: GENERIC_ERROR,
  [USE_HOMEVISIT.HOMEVISIT_UPDATE]:
    'Sorry, we cannot reschedule your visit right now, please contact your Strike Agent to do this for you.',
};

/* *************************************************** */
/* useTimeline                                         */
/* *************************************************** */
export const TIMELINE_SUCCESS_ALERTS = {
  [USE_TIMELINE.TIMELINE_FETCH]: null,
};

export const TIMELINE_FAILED_ALERTS = {
  [USE_HOMEVISIT.TIMELINE_FETCH]: GENERIC_ERROR,
};

/* *************************************************** */
/* useLogin                                            */
/* *************************************************** */
export const LOGIN_SUCCESS_ALERTS = {
  [USE_LOGIN.SUBMIT_EMAIL]: 'Your code has been emailed',
  [USE_LOGIN.SUBMIT_CODE]: null,
};
export const LOGIN_FAILED_ALERTS = {
  [USE_LOGIN.SUBMIT_EMAIL]:
    "Sorry, that's embarrassing. We're currently experiencing some technical issues with users logging in. You can phone us on 0333 103 8390 or email us at info@strike.co.uk to help with anything.",
  [USE_LOGIN.SUBMIT_CODE]: "We're sorry - that's not a valid code",
};

/* *************************************************** */
/* useLogin                                            */
/* *************************************************** */
export const USER_SUCCESS_ALERTS = {
  [USE_USER.USER_DETAILS_UPDATE]: 'Your personal information has been updated',
  [USE_USER.USER_FEEDBACK]: 'Thank you for your feedback',
};

export const USER_FAILED_ALERTS = {
  [USE_USER.USER_DETAILS_UPDATE]:
    'Sorry, your personal details could not be saved right now.',
  [USE_USER.USER_FEEDBACK]: GENERIC_ERROR,
};

/* *************************************************** */
/* usePropertyDetails                                  */
/* *************************************************** */
export const PROPERTY_DETAILS_SUCCESS_ALERTS = {
  [USE_PROPERTY_DETAILS.UPDATE_PROPERTY_DETAILS]:
    'Your advert details have been saved',
};

export const PROPERTY_DETAILS_FAILED_ALERTS = {
  [USE_PROPERTY_DETAILS.UPDATE_PROPERTY_DETAILS]:
    "Sorry we couldn't save your property details at this time. You can try again later or contact your Strike Agent who can do this for you.",
};

/* *************************************************** */
/* useGoLive                                  */
/* *************************************************** */
export const GO_LIVE_SUCCESS_ALERTS = {
  [USE_ADVERT.APPROVE_ADVERT]: null,
  [USE_ADVERT.MARKET_ADVERT]: null,
  [USE_ADVERT.ACCEPT_TERMS]: null,
};

export const GO_LIVE_FAILED_ALERTS = {
  [USE_ADVERT.APPROVE_ADVERT]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk and we'll put your property on Strike.",
  [USE_ADVERT.MARKET_ADVERT]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk and we'll put your property on Strike.",
  [USE_ADVERT.ACCEPT_TERMS]:
    "Sorry, that's frustrating. We're currently experiencing some technical issues. You can phone us on 0333 103 8390 or email us at info@strike.co.uk and we'll put your property on Strike.",
};

/* *************************************************** */
/* useFileManager                                  */
/* *************************************************** */
export const FILE_MANAGER_SUCCESS_ALERTS = {
  [USE_FILEMANAGER.UPLOAD_FILE]: 'Your files have been uploaded',
  [USE_FILEMANAGER.UPDATE_FILE]: 'Your files have been updated',
  [USE_FILEMANAGER.DELETE_FILE]: 'Your files have been deleted',
};

export const FILE_MANAGER_FAILED_ALERTS = {
  [USE_FILEMANAGER.UPLOAD_FILE]:
    "Sorry we couldn't upload your file at this time. You can try again later or contact your Strike Agent who can do this for you.",
  [USE_FILEMANAGER.UPDATE_FILE]:
    "Sorry we couldn't update your documents at this time. You can try again later or contact your Strike Agent who can do this for you.",
  [USE_FILEMANAGER.DELETE_FILE]:
    "Sorry we couldn't delete your documents at this time. You can try again later or contact your Strike Agent who can do this for you.",
};

/* *************************************************** */
/* useEpc                                  */
/* *************************************************** */
export const EPC_SUCCESS_ALERTS = {
  'USE_EPC.MANUAL_EPC': 'Your EPC is confirmed',
};

export const EPC_FAILED_ALERTS = {
  'USE_EPC.MANUAL_EPC': GENERIC_ERROR,
};

/* *************************************************** */
/* useCommsPreferences                                 */
/* *************************************************** */
export const COMMS_PREFERENCES_SUCCESS_ALERTS = {
  [USE_COMMS_PREFERENCES.UPDATE_COMMS_PREFERENCES]:
    'Your preferences have been saved',
};

export const COMMS_PREFERENCES_FAILED_ALERTS = {
  [USE_COMMS_PREFERENCES.FETCH_COMMS_PREFERENCES]:
    "Sorry, we're currently experiencing some technical issues. Speak to us on 0333 103 8390 or email us at info@strike.co.uk and we can arrange this for you.",
  [USE_COMMS_PREFERENCES.UPDATE_COMMS_PREFERENCES]:
    "Sorry we couldn't update your preferences at this time. You can try again later or contact your Strike Agent who can do this for you.",
};

/* *************************************************** */
/* useWatchedProperties                                 */
/* *************************************************** */
export const WATCHED_PROPERTIES_SUCCESS_ALERTS = {
  [USE_WATCHED_PROPERTIES.TOGGLE_WATCHED_PROPERTY]:
    'Your preferences have been saved',
  [USE_WATCHED_PROPERTIES.DELETE_WATCHED_PROPERTY]:
    "You've removed this property",
};

export const WATCHED_PROPERTIES_FAILED_ALERTS = {
  [USE_WATCHED_PROPERTIES.DELETE_WATCHED_PROPERTY]:
    "Sorry, we're currently experiencing some technical issues. Speak to us on 0333 103 8390 or email us at info@strike.co.uk and we can arrange this for you.",
  [USE_WATCHED_PROPERTIES.TOGGLE_WATCHED_PROPERTY]:
    "Sorry we couldn't update your preferences at this time. You can try again later or contact your Strike Agent who can do this for you.",
};

/* *************************************************** */
/* useSellerAvailability                               */
/* *************************************************** */
export const SELLER_AVAILABILITY_SUCCESS_ALERTS = {
  [USE_SELLER_AVAILABILITY.SET_UNAVAILABILITY]: 'Availability updated',
  [USE_SELLER_AVAILABILITY.REMOVE_UNAVAILABILITY]: 'Availability updated',
};

export const SELLER_AVAILABILITY_FAILED_ALERTS = {
  [USE_SELLER_AVAILABILITY.SET_UNAVAILABILITY]: GENERIC_ERROR,
  [USE_SELLER_AVAILABILITY.REMOVE_UNAVAILABILITY]: GENERIC_ERROR,
};

export default {
  [SUCCESS]: {
    ...OFFER_SUCCESS_ALERTS,
    ...VIEWINGS_SUCCESS_ALERTS,
    ...HOMEVISIT_SUCCESS_ALERTS,
    ...TIMELINE_SUCCESS_ALERTS,
    ...LOGIN_SUCCESS_ALERTS,
    ...USER_SUCCESS_ALERTS,
    ...PROPERTY_DETAILS_SUCCESS_ALERTS,
    ...GO_LIVE_SUCCESS_ALERTS,
    ...FILE_MANAGER_SUCCESS_ALERTS,
    ...EPC_SUCCESS_ALERTS,
    ...COMMS_PREFERENCES_SUCCESS_ALERTS,
    ...WATCHED_PROPERTIES_SUCCESS_ALERTS,
    ...SELLER_AVAILABILITY_SUCCESS_ALERTS,
  },
  [FAILED]: {
    ...OFFER_FAILED_ALERTS,
    ...VIEWINGS_FAILED_ALERTS,
    ...HOMEVISIT_FAILED_ALERTS,
    ...TIMELINE_FAILED_ALERTS,
    ...LOGIN_FAILED_ALERTS,
    ...USER_FAILED_ALERTS,
    ...PROPERTY_DETAILS_FAILED_ALERTS,
    ...GO_LIVE_FAILED_ALERTS,
    ...FILE_MANAGER_FAILED_ALERTS,
    ...EPC_FAILED_ALERTS,
    ...COMMS_PREFERENCES_FAILED_ALERTS,
    ...WATCHED_PROPERTIES_FAILED_ALERTS,
    ...SELLER_AVAILABILITY_FAILED_ALERTS,
  },
};
