import { PURGE } from 'constants/actions';
import { arrayToObject } from '@strikelabs/pax';

export const initialState = {};
export const FETCHED_SUMMARY = 'FETCHED_SUMMARY';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_SUMMARY: {
      return {
        ...state,
        ...arrayToObject(action.payload, 'propertyId'),
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
