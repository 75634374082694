import { useWebSend } from '@strikelabs/vega';

import useToast from 'hooks/utils/useToast';

const useSendHook = (props) => {
  const hook = useWebSend(props);
  useToast(hook[0]);

  return hook;
};

export default useSendHook;
