import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  SUCCESS,
  useOrders as useOrdersAPI,
  useSyncState,
} from '@strikelabs/vega';

import useSendHook from 'hooks/utils/useSendHook';
import useGetHook from 'hooks/utils/useGetHook';
import { FETCHED_ORDERS } from 'reducers/orders';
import { useOrdersSelector } from 'selectors/orders';

const useOrders = () => {
  const dispatch = useDispatch();
  const [cancelling, setCancelling] = useState(false);
  const orders = useOrdersSelector();

  const { fetchAllOrdersState, fetchAllOrders, cancelOrderState, cancelOrder } =
    useOrdersAPI({
      useGetHook,
      useSendHook,
      baseUrl: process.env.BASE_API_URL,
      autoGetOrders: true,
    });

  const isOrderFree = (order) => {
    if (order.subTotal > 0) {
      return false;
    }

    return true;
  };

  const items = useMemo(() => {
    if (!orders.length) return null;

    /**
     * Sort by status
     */
    return orders.reduce(
      (acc, current) => {
        const status = current.paymentStatus;

        /**
         * Check whether the order's sub total is greater than 0.
         * Free orders are used as flags against user accounts rather than
         * payable items, therefore cannot be processed via Stripe.
         *
         * Remove failed orders. Current UI is not able to handle these orders
         */
        if (isOrderFree(current) || status === 'failed') {
          return acc;
        }

        return {
          ...acc,
          [status]: [...acc[status], current],
        };
      },
      {
        unpaid: [],
        paid: [],
        cancelled: [],
      }
    );
  }, [orders]);

  const handleRemoveOrder = async (orderId) => {
    setCancelling(true);
    cancelOrder(orderId);
  };

  useEffect(() => {
    if (cancelOrderState.status === SUCCESS) {
      fetchAllOrders();
    }

    if (cancelOrderState.loading === false && cancelling) {
      setCancelling(false);
    }
  }, [cancelOrderState]);

  const action = useCallback(
    () =>
      dispatch({
        type: FETCHED_ORDERS,
        payload: fetchAllOrdersState.data,
      }),
    [fetchAllOrdersState.data, dispatch]
  );

  useSyncState(action, fetchAllOrdersState.status === SUCCESS);

  return {
    items,
    cancelling,
    loading: fetchAllOrdersState.loading,
    handleRemoveOrder,
  };
};

export default useOrders;
