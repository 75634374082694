import { useCallback } from 'react';
import { useSyncState, SUCCESS } from '@strikelabs/vega';
import { useDispatch } from 'react-redux';

import useGetHook from 'hooks/utils/useGetHook';
import { PROPERTIES_ACTIONS } from 'reducers/properties';

const { BASE_API_URL } = process.env;

const useProperties = ({ autoGet } = {}) => {
  const dispatch = useDispatch();
  const [state, fetchProperties] = useGetHook({
    url: `${BASE_API_URL}/listings?vendor_limit=0&buyer_limit=0`,
    autoGet,
    authRequest: true,
    config: {
      headers: {
        Accept: 'application/hal+json;version=3',
      },
    },
  });

  const action = useCallback(
    () =>
      dispatch({
        type: PROPERTIES_ACTIONS.RECEIVED_PROPERTIES,
        payload: state.data,
      }),
    [state.data, dispatch]
  );

  useSyncState(action, state.status === SUCCESS);

  return {
    fetchPropertiesState: state,
    fetchProperties,
  };
};

export default useProperties;
