import styled, { css } from 'styled-components';
import { Box } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(Box)`
  color: ${({ theme, color }) => theme.colors[color] || color};
  background-color: ${({ theme, background }) =>
    theme.colors[background] || background};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ small }) => {
    if (small) {
      return css`
        padding: 0 4px;
        min-width: 16px;
        height: 16px;
        font-size: ${rem(8)};
        border-radius: 16px;
      `;
    }

    return css`
      padding: 0 8px;
      min-width: 24px;
      height: 24px;
      font-size: ${rem(10)};
      border-radius: 24px;
    `;
  }}
`;
