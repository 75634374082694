import React from 'react';
import { Box, Icon, useIsMedia } from '@strikelabs/luna';
import ContentLoader from 'react-content-loader';

import { Wrapper } from '../BaseLayout/style';

import { Header } from './style';

const Placeholder = () => {
  const { tablet } = useIsMedia(true);

  return (
    <>
      <Header>
        <Wrapper>
          <Box ml={1}>
            <Icon width="94" type="strikeLogo" />
          </Box>
        </Wrapper>
      </Header>
      <Wrapper>
        {tablet ? (
          <ContentLoader viewBox="0 0 1350 700">
            <rect x="16" y="30" rx="10" ry="10" width="250" height="340" />
            <rect x="282" y="0" rx="0" ry="0" width="729" height="320" />
            <rect x="1029" y="30" rx="10" ry="10" width="305" height="150" />
            <rect x="282" y="345" rx="10" ry="10" width="729" height="150" />
            <rect x="282" y="520" rx="10" ry="10" width="729" height="150" />
          </ContentLoader>
        ) : (
          <ContentLoader viewBox="0 0 800 900">
            <rect x="0" y="0" width="100%" height="270" />
            <rect x="16" y="302" rx="10" ry="10" width="768" height="250" />
            <rect x="16" y="588" rx="10" ry="10" width="768" height="150" />
          </ContentLoader>
        )}
      </Wrapper>
    </>
  );
};

export default Placeholder;
