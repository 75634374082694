import styled from 'styled-components';
import { Box, Flex } from '@strikelabs/luna';
import { NavLink } from 'react-router-dom';

export const StyledFlex = styled(Flex)`
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.light};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;

export const MenuLink = styled(NavLink)`
  text-decoration: none;

  &.active {
    background: ${({ theme }) => theme.colors.ultraLight};
    border-radius: 10px;
  }
`;

export const MenuItem = styled(Box)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.ultraLight};
  }
`;
