import merge from 'lodash/merge';

import { PURGE } from 'constants/actions';
import {
  transformPropertiesPayload,
  formatPropertyDetails,
  getPropertyMode,
} from 'utils/properties';

export const PROPERTIES_ACTIONS = {
  RECEIVED_PROPERTIES: 'RECEIVED_PROPERTIES',
  RECEIVED_PROPERTY_DETAILS: 'RECEIVED_PROPERTY_DETAILS',
  RECEIVED_DRAFT_PROPERTY_DETAILS: 'RECEIVED_DRAFT_PROPERTY_DETAILS',
  RECEIVED_TIMELINE: 'RECEIVED_TIMELINE',
};

const initialState = {
  selling: {},
  buying: {},
  loaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTIES_ACTIONS.RECEIVED_PROPERTIES: {
      const { payload } = action;

      return {
        buying: transformPropertiesPayload(payload?.buyer, state.buying),
        selling: transformPropertiesPayload(payload?.vendor, state.selling),
        loaded: true,
      };
    }

    case PROPERTIES_ACTIONS.RECEIVED_PROPERTY_DETAILS: {
      const { payload } = action;
      const { isSelling } = payload;
      const { label, newState } = getPropertyMode(isSelling, state);

      return {
        ...state,
        [label]: merge({}, newState, {
          [payload.id]: formatPropertyDetails({ payload, isDraft: false }),
        }),
      };
    }

    case PROPERTIES_ACTIONS.RECEIVED_DRAFT_PROPERTY_DETAILS: {
      const { payload } = action;
      const { isSelling } = payload;
      const { label, newState } = getPropertyMode(isSelling, state);

      return {
        ...state,
        [label]: merge({}, newState, {
          [payload.id]: formatPropertyDetails({ payload, isDraft: true }),
        }),
      };
    }

    case PROPERTIES_ACTIONS.RECEIVED_TIMELINE: {
      const {
        payload: { id, timeline, status, isPublishable },
      } = action;

      return {
        ...state,
        selling: {
          ...state.selling,
          [id]: {
            ...state.selling[id],
            timeline,
            timelineStatus: status,
            isPublishable,
          },
        },
      };
    }
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
