import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { setAppElement, Toaster, themes } from '@strikelabs/luna';
import { Provider } from 'react-redux';
import {
  AuthProvider,
  AppConfigProvider,
  ABTestProvider,
  useWebSend,
} from '@strikelabs/vega';

import GlobalStyle from 'theme/global';
import Routes from 'routes';
import { store } from 'store';
import ScrollToTop from 'components/ScrollToTop';
import { AutoRefreshingRouter as Router } from 'components/AutoRefreshingRouter';
import ModeProvider from 'providers/ModeProvider';
import AccessProvider from 'providers/AccessProvider';
import AppLoadedProvider from 'providers/AppLoadedProvider';

/**
 * REACT APP ROOT
 */
const App = () => {
  useEffect(() => {
    setAppElement('#app');
  }, []);

  return (
    <AppConfigProvider appName={process.env.SERVICE_NAME}>
      <ABTestProvider
        uaId={process.env.UA_ID}
        useSendHook={useWebSend}
        baseUrl={process.env.API_URL}
      >
        <ThemeProvider theme={themes.clientHub}>
          <GlobalStyle />
          <Toaster />
          <Provider store={store}>
            <AuthProvider
              baseUrl={process.env.BASE_API_URL}
              handleAnonymous={() => {
                window.location.href = `${
                  process.env.AUTH_SITE_URL
                }?referrer=${window.location.href.replace('profile', '')}`;
              }}
            >
              <AccessProvider>
                <Router>
                  <AppLoadedProvider>
                    <ModeProvider>
                      <>
                        <ScrollToTop />
                        <Routes />
                      </>
                    </ModeProvider>
                  </AppLoadedProvider>
                </Router>
              </AccessProvider>
            </AuthProvider>
          </Provider>
        </ThemeProvider>
      </ABTestProvider>
    </AppConfigProvider>
  );
};

export default App;
