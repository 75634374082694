import { useSelector } from 'react-redux';

export const useNotifiationsSelector = () => {
  const notifications = useSelector((state) => state.notifications);

  return notifications;
};

export const useNotifiationsListSelector = () => {
  const { list } = useNotifiationsSelector();

  return list;
};

export const useNotifiationsBadgeSelector = () => {
  const { badge } = useNotifiationsSelector();

  return badge;
};
