import { useWebGet } from '@strikelabs/vega';

import useToast from 'hooks/utils/useToast';

const useGetHook = (props) => {
  const hook = useWebGet(props);
  useToast(hook[0]);

  return hook;
};

export default useGetHook;
