export const SUPPLIER = 'supplier';
export const DEFAULT_AGENT = 'default';
export const MOVE_CONSULTANT = 'move_consultant';
export const NEGOTIATOR = 'negotiator';
export const SALES_PROGRESSOR = 'sales_progressor';
export const AGENT = 'agent';
export const ACCOUNT_MANAGER = 'account_management';

export const AGENT_TITLES = {
  account_management: 'Account Manager',
};
