import { useSelector } from 'react-redux';

export const useChatListSelector = () => {
  const chatList = useSelector((state) => state.chat.list);

  return chatList;
};

export const useChatSelector = (id) => {
  const chatList = useSelector((state) => state.chat.list);

  return chatList.find((chat) => chat.id === id) || {};
};

export const useMessagesSelector = (id) => {
  const messages = useSelector((state) => state.chat.messages);

  if (id && messages[id]) {
    return messages[id] || {};
  }

  return messages;
};

export const useUnreadMessagesCountSelector = () => {
  const chatList = useChatListSelector();

  // Count totlal unread messages
  return chatList.reduce((prev, curr) => prev + curr.unread_message_count, 0);
};
