import { useCallback } from 'react';
import {
  useChatList as useChatListAPI,
  SUCCESS,
  useSyncState,
} from '@strikelabs/vega';
import { useDispatch } from 'react-redux';

import useGetHook from 'hooks/utils/useGetHook';
import { RECEIVED_CHAT_LIST } from 'reducers/chat';
import { useUserSelector } from 'selectors/user';

const useChatList = ({ autoGet } = { autoGet: true }) => {
  const user = useUserSelector();
  const dispatch = useDispatch();
  const state = useChatListAPI({
    baseUrl: process.env.BASE_API_URL,
    useGetHook,
    autoGet,
  });

  const [{ data, status }] = state;

  const fetchChatListAction = useCallback(
    () =>
      dispatch({
        type: RECEIVED_CHAT_LIST,
        payload: { data, userId: user?.id },
      }),
    [data, dispatch]
  );

  useSyncState(fetchChatListAction, status === SUCCESS);

  return state;
};

export default useChatList;
