import { PURGE } from 'constants/actions';
export const RECEIVED_NOTIFICATION = 'RECEIVED_NOTIFICATION';
export const RECEIVED_NOTIFICATIONS = 'RECEIVED_NOTIFICATIONS';
export const UPDATE_NOTIFCATIONS_BADGE = 'UPDATE_NOTIFCATIONS_BADGE';

export const initialState = {
  list: [],
  badge: null,
};

const formatNotification = (val) => {
  if (val.data?.text && val.data?.timestamp && val.data?.status) {
    return val.data;
  }

  return {
    ...val.data,
    text: val.text,
    timestamp: val.timestamp,
    status: val.status,
  };
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_NOTIFICATIONS:
      return {
        ...state,
        list: action.payload.map((item) => formatNotification(item)),
      };

    case RECEIVED_NOTIFICATION:
      return {
        ...state,
        list: [action.payload, ...state.list],
        badge: action.payload.badge,
      };

    case UPDATE_NOTIFCATIONS_BADGE:
      return {
        ...state,
        badge: action.payload?.badge,
      };

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default notifications;
