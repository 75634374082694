// COMMON
export const PENDING_OWNER = 'pending_owner';
export const COMPLETE = 'complete';

// VIEWINGS
export const PENDING_VIEWER = 'pending_viewer';
export const CONFIRMED = 'confirmed';
export const CANCELLED = 'cancelled';
export const EXPIRED = 'expired';
export const NO_SHOW = 'no_show';
export const AWAITING_FEEDBACK = 'awaiting_feedback';
export const UNCONFIRMED = 'unconfirmed';
export const PENDING_OWNER_HOSTED = 'pending_owner_hosted';
export const PENDING_VIEWER_HOSTED = 'pending_viewer_hosted';
export const CONFIRMED_HOSTED = 'confirmed_hosted';

export const HOSTED = {
  [PENDING_VIEWER]: PENDING_VIEWER_HOSTED,
  [PENDING_OWNER]: PENDING_OWNER_HOSTED,
  [CONFIRMED]: CONFIRMED_HOSTED,
};

// OFFERS
export const WITHDRAWN = 'withdrawn';
export const REJECTED = 'rejected';
export const ACCEPTED = 'accepted';
export const PENDING_AGENT = 'pending_housesimple';
export const PENDING_OFFEROR = 'pending_offeror';
export const BUYER_ACCEPTED = 'buyer_accepted';
export const AWAITING_DOCUMENTS = 'awaiting_documents';
export const IN_PROGRESSION = 'in_progression';
export const PENDING_AGENT_OWNER = 'pending_housesimple_owner';
export const PENDING_AGENT_OFFEROR = 'pending_housesimple_offeror';

export const MARKETING_STATUS = {
  AVAILABLE: 'Available',
  LET: 'Let',
  LET_AGREED: 'Let Agreed',
  SOLD: 'Sold',
  SOLD_STC: 'Sold STC',
  SOLD_STCM: 'Sold STCM',
  UNDER_OFFER: 'Under Offer',
  UNKNOWN: 'Unknown',
  ARCHIVED: 'Archived',
};

// OFFER SUBSTANTIATIONS STATUS
export const NOBODY_NEGOTIATED = 'nobody_negotiated';
export const BUYER_NEGOTIATED = 'buyer_negotiated';
export const VENDOR_NEGOTIATED = 'vendor_negotiated';
export const VENDOR_BUYER_NEGOTIATED = 'vendor_buyer_negotiated';

// WITHDRAWN STATUS
export const SELLER_WITHDREW = 'seller_withdrew';
export const BUYER_WITHDREW = 'buyer_withdrew';
export const NOT_WITHDRAWN = 'not_withdrawn';
