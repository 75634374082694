import { init as apm } from '@elastic/apm-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import LogRocket from 'logrocket';
const serviceVersion = require('../package.json').version;

import App from './App';

if (process.env.GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.GTM_ID,
  });
}

if (process.env.LOG_ROCKET_ID) {
  LogRocket.init(process.env.LOG_ROCKET_ID);
}

if (process.env.SERVICE_NAME && process.env.APM_SERVER_URL) {
  apm({
    serviceName: process.env.SERVICE_NAME,
    serverUrl: process.env.APM_SERVER_URL,
    pageLoadTransactionName: window.location.pathname,
    serviceVersion,
  });
}

const render = () => {
  ReactDOM.render(<App />, document.getElementById('app'));

  // After render apply optimize changes
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'optimize.activate' });
  }
};

if (process.env.MOCK === 'true') {
  import('./mock/server').then((module) => {
    module.init({
      environment: process.env.MOCK_SERVER_ENVIRONMENT,
      urlPrefix: process.env.BASE_API_URL,
    });

    render();
  });
} else {
  render();
}
