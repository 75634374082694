import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Icon, useIsMedia, Box } from '@strikelabs/luna';
import { identifier } from '@strikelabs/pax';

import { useNotifiationsBadgeSelector } from 'selectors/notifications';

import { Outer, Container, Badge, BadgeText } from './style';
import Dropdown from './Dropdown';

const NotificationsBell = () => {
  const { isMobile } = useIsMedia(true);
  const [show, setShow] = useState(false);
  const { push } = useHistory();
  const badge = useNotifiationsBadgeSelector();

  const onPress = useCallback(() => {
    push('/notifications');
  }, []);

  return (
    <Box>
      <Outer
        onMouseLeave={() => setShow(false)}
        onMouseOver={() => setShow(true)}
        onClick={onPress}
      >
        <Container
          className={`badge-number-${badge}`}
          {...identifier({ name: 'notification-bell', requireId: false })}
        >
          <Icon type="bellV2" width={15} height={20} color="lightIcon" />
          {!!badge && (
            <Badge>
              <BadgeText>{badge}</BadgeText>
            </Badge>
          )}
        </Container>
        {!isMobile && show && (
          <Dropdown
            onMouseLeave={() => setShow(false)}
            onMouseOver={() => setShow(true)}
          />
        )}
      </Outer>
    </Box>
  );
};

export default memo(NotificationsBell);

NotificationsBell.propTypes = {
  darkMode: PropTypes.bool,
};

NotificationsBell.defaultProps = {
  darkMode: false,
};
