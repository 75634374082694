import { PURGE } from 'constants/actions';
export const FETCHED_ORDERS = 'FETCHED_ORDERS';
export const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ORDERS: {
      const { payload } = action;
      return payload;
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
