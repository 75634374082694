import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { NOTIFICATION_STATUS } from '@strikelabs/vega';
import { BlankButton } from '@strikelabs/luna';
import { rem } from 'polished';

export const Outer = styled(BlankButton)`
  width: 100%;
  text-align: left;
`;

export const Container = styled(Flex)`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.rg}`};
  background-color: ${({ status, theme }) =>
    [NOTIFICATION_STATUS.READ, NOTIFICATION_STATUS.DELETED].includes(status)
      ? theme.colors.white
      : theme.colors.ultraLight};

  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
  }
  align-items: center;
`;

export const Title = styled.p`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
`;

export const Caption = styled.p`
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const IconContainer = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light};
`;
