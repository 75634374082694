import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { identifier } from '@strikelabs/pax';
import { Flex, Text, Icon } from '@strikelabs/luna';

import Badge from 'components/_common/Badge';

import { MenuItem, MenuLink, StyledFlex } from './style';

const DesktopNavigation = ({ menu }) => {
  const menuItems = useMemo(() => {
    return menu.map((menuItem) => {
      return (
        <MenuLink
          key={menuItem.selector}
          exact={menuItem.title === 'Dashboard'}
          {...identifier({ name: 'menu', id: menuItem.selector })}
          to={menuItem.route}
        >
          <MenuItem p={1}>
            <Flex alignItems="center">
              <Icon type={menuItem.icon} width={menuItem.iconWidth} />
              <Text ml={1} styleType="utility">
                {menuItem.title}
              </Text>
              <Badge small background="statusError" color="white" ml={0}>
                {menuItem.badge}
              </Badge>
            </Flex>
            <Icon type="chevronRight" width="10" />
          </MenuItem>
        </MenuLink>
      );
    });
  }, [menu]);

  return (
    <StyledFlex
      py={2}
      px={0}
      flexDirection="column"
      alignContent="center"
      role="navigation"
    >
      <Text px={1} pb={0} as="p" color="helperText">
        STRIKE HUB
      </Text>
      {menuItems}
    </StyledFlex>
  );
};

DesktopNavigation.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
};

export default memo(DesktopNavigation);
