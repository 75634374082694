import { createGlobalStyle } from 'styled-components';
import { resetCss } from '@strikelabs/luna';

const GlobalStyle = createGlobalStyle`
${resetCss}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
  body {
    margin: 0;
    background: ${({ theme }) => theme.colors.ultraLight};
    font-size: ${({ theme }) => theme.defaults.fontSize};
    font-family: ${({ theme }) => theme.defaults.fontFamily};
    line-height: inherit;
  }
  h1, h2, h3, h4, h5, h6, span, a, p {
    margin: 0;
  }

  a {
    color: inherit;
  }
`;
export default GlobalStyle;
