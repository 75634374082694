import { useSelector } from 'react-redux';
import {
  getViewings,
  getViewingById,
  getViewingsList,
  getCurrentViewings,
  getArchivedViewings,
  getViewingsByPropertyId,
  getViewingByStatus,
  getViewingsByType,
} from '@strikelabs/vega';
import { orderBy } from 'lodash';
import { getTime, parseISO } from 'date-fns';

import {
  NO_SHOW,
  AWAITING_FEEDBACK,
  UNCONFIRMED,
  COMPLETE,
} from 'constants/status';
import { usePropertiesSelector } from 'selectors/properties';

export const useViewingsSelector = () => {
  const viewings = useSelector((state) => state.viewings.list);
  const transformedViewings = getViewings({ viewings });

  return transformedViewings;
};

export const useViewingsLoadedSelector = () => {
  return useSelector((state) => state.viewings.loaded);
};

export const useViewingByIdSelector = ({ viewingId }) => {
  const viewings = useSelector((state) => state?.viewings?.list);
  const viewing = getViewingById({ viewingId, viewings });

  return viewing;
};

export const useViewingsListSelector = ({ type }) => {
  const viewings = useViewingsSelector();
  const list = getViewingsList({ viewings, type });

  return list;
};

export const useCurrentViewingsSelector = () => {
  const viewings = useViewingsSelector();
  const currentViewings = getCurrentViewings({ viewings });

  return currentViewings;
};

export const useArchivedViewingsSelector = () => {
  const viewings = useViewingsSelector();
  const archivedViewings = getArchivedViewings({ viewings });

  return archivedViewings;
};

export const useViewingsByPropertyIdSelector = ({ propertyId }) => {
  const viewings = useViewingsSelector();
  const viewngsByProperty = getViewingsByPropertyId({ viewings, propertyId });

  return viewngsByProperty;
};

export const useViewingByStatusSelector = ({ status }) => {
  const viewings = useViewingsSelector();
  const viewingsByStatus = getViewingByStatus({ viewings, status });

  return viewingsByStatus;
};

export const useLatestCompletedViewingSelector = ({ propertyId }) => {
  const viewings = useViewingsByPropertyIdSelector({ propertyId });
  const eligibleViewings = viewings.filter((item) =>
    [NO_SHOW, COMPLETE, AWAITING_FEEDBACK, UNCONFIRMED].includes(item.status)
  );

  /*
   * Always return the latest viewing
   */
  return eligibleViewings[0] || {};
};

export const useLatestViewingWithoutFeedback = () => {
  const viewings = useViewingsSelector();
  const completeViewingsWithoutFeedback = viewings.filter(
    (item) =>
      item.status === COMPLETE &&
      !item.legacyFeedback &&
      !item.surveyResponse &&
      !item.isVendor
  );
  // return the latest always
  return completeViewingsWithoutFeedback[0] || {};
};

export const useViewingsBySellingBuyingSelector = () => {
  const viewings = useViewingsSelector();
  return viewings.reduce(
    (acc, curr) => {
      if (curr.isVendor) {
        return {
          ...acc,
          selling: [...acc.selling, curr],
        };
      }
      return {
        ...acc,
        buying: [...acc.buying, curr],
      };
    },
    {
      selling: [],
      buying: [],
    }
  );
};

export const useViewingByTypeSelector = () => {
  const viewings = useViewingsBySellingBuyingSelector();
  const selling = getViewingsByType(viewings.selling);
  const buying = getViewingsByType(viewings.buying);

  return {
    selling,
    buying,
  };
};

export const useHasUpcomingViewings = () => {
  const { buying } = useViewingByTypeSelector();

  return buying.upcoming?.length > 0 || buying.unconfirmed?.length > 0;
};

export const useUpcomingViewingsSelector = () => {
  const viewings = useViewingsBySellingBuyingSelector();
  const { upcoming } = getViewingsByType(viewings.buying);
  const properties = usePropertiesSelector('buying');

  const viewingsFiltered = upcoming.filter(
    (viewing) =>
      Date.parse(parseISO(viewing.confirmedTime)) > Date.parse(new Date())
  );

  const orderedByDate = orderBy(viewingsFiltered, (date) =>
    getTime(parseISO(date.confirmedTime))
  );

  return orderedByDate.map((data) => {
    const { simpleStatus, price, address } = properties[data.property.id];

    return {
      ...data,
      price,
      simpleStatus,
      address,
    };
  });
};
