import { useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { getTime, parseISO } from 'date-fns';
import { SIMPLIFIED_PROPERTY_STATUS, VIEWING_STATUS } from '@strikelabs/vega';

import { useViewingByTypeSelector } from 'selectors/viewings';
import { useFilesSelectorByCategory } from 'selectors/files';
import { PHOTOS } from 'constants/files';
import { BUYING, SELLING } from 'constants/mode';
import { useMiscSelector } from 'selectors/misc';

/*
 * type: 'buying' | 'selling' or all if not provided
 */
export const usePropertiesSelector = (type) => {
  const properties = useSelector((state) => state?.properties);

  if (type) {
    return properties[type];
  }

  return { ...properties.buying, ...properties.selling };
};

export const usePropertiesArraySelector = (type) => {
  const properties = usePropertiesSelector(type);
  const keys = Object.keys(properties);

  if (!keys.length) {
    return [];
  }

  return keys.map((key) => properties[key]);
};

export const usePropertySelector = (id) => {
  const properties = usePropertiesSelector();

  if (properties[id] !== undefined) {
    return properties[id];
  }

  return {};
};

export const usePropertiesLoadedSelector = () => {
  const loaded = useSelector((state) => state?.properties?.loaded);

  return loaded;
};

export const usePropertyMarketingStatusSelector = (propertyId) => {
  const { simpleStatus } = usePropertySelector(propertyId);

  return simpleStatus?.status;
};

export const usePropertyImageSelector = (propertyId, propertyDetails) => {
  const property = usePropertySelector(propertyId);
  const { activeMode } = useMiscSelector();

  if (isEmpty(property)) {
    return null;
  }

  const { files, revisions, primaryPhoto } = property;

  // When deleting an image in property details we don't want to show the image that's cached in primary photo
  if (activeMode === SELLING && propertyDetails) {
    if (!revisions?.current?.filesObject?.photos) {
      return null;
    }
  }

  // /*
  //  * PRIMARY
  //  * This object contains photos displays for property
  //  * details section coming from usePropertyDetails hook.
  //  * These images should be more up to date than the images
  //  * that we have on useProperties since this don't get updated as often.
  //  */
  if (revisions?.current?.filesObject?.photos?.length) {
    return revisions.current.filesObject.photos[0];
  }

  /*
   * FALLBACK
   * This images gets pulled form data that comes from useProperties hook.
   * This image will always be available as soon as app is loaded,
   * that's why this needs to be set as the default.
   */
  const images =
    files?.filter((item) => item?.file?.category === 'photo') || [];
  const fallbackImage = images[0]?._links?.format_property_large?.href || null;
  if (fallbackImage) {
    return fallbackImage;
  }

  /*
   * FALLBACK OPTION TWO
   * If above images are not found we take new image
   * that gets returned from properties list API v3
   */
  if (primaryPhoto === '/images/no-img-large.png') {
    // Temp workaround until back-end removes this response
    return null;
  }

  return primaryPhoto;
};

export const usePropertyDetailsImagesSelector = (propertyId) => {
  const { revisions } = usePropertySelector(propertyId);
  const photos = useFilesSelectorByCategory({ propertyId, category: PHOTOS });

  if (!revisions || !photos) {
    return [];
  }

  const { draft, current } = revisions;

  const revision = draft?.filesObject?.photos ? draft : current;

  /*
   * PRIMARY
   * This image will be updated as soon as files are modified on the property,
   * as soon as it's available we want to make sure we use the latest imageS from here.
   */
  if (photos.length) {
    const sliced = photos.slice(0, 3);
    return sliced.map((img) => img.src);
  }

  /*
   * FALLBACK
   * This object contains photos displays for property
   * details section coming from usePropertyDetails hook.
   * We want to display this be default until photos get fetched for PRIMARY option.
   */
  return revision?.filesObject?.photos?.slice(0, 3) || [];
};

export const useQuestionnaireCompletionSelector = (propertyId) => {
  const { questionnaireCompletion } = usePropertySelector(propertyId);

  if (typeof questionnaireCompletion === 'undefined') {
    return '0%';
  }

  return `${Math.round(questionnaireCompletion * 100)}%`;
};

export const useCanPreviewAdSelector = (propertyId) => {
  const { questionnaireCompletion, revisions, simpleStatus } =
    usePropertySelector({
      propertyId,
    });

  if (simpleStatus.status === SIMPLIFIED_PROPERTY_STATUS.SOLD) {
    return false;
  }

  return (
    questionnaireCompletion === 1 &&
    !!revisions?.current?.filesObject?.photos?.length
  );
};

export const usePropertySimpleStatus = (mode, propertyId) => {
  const type = mode === BUYING ? 'buying' : 'selling';
  const property = useSelector((state) => state.properties[type][propertyId]);
  return property?.simpleStatus?.status;
};

export const useViewedPropertiesSelector = () => {
  const {
    buying: { past },
  } = useViewingByTypeSelector();
  const properties = usePropertiesSelector('buying');
  const uniquePastViewings = uniqBy(past, 'property.id', (date) =>
    getTime(parseISO(date.confirmedTime))
  );

  const confirmedViewings = uniquePastViewings.filter(
    (viewing) => viewing.status === VIEWING_STATUS.COMPLETE
  );

  return confirmedViewings.map(({ id, property, surveyResponse }) => {
    const { simpleStatus, address, primaryPhoto, price } =
      properties[property.id];

    return {
      ...property,
      viewingId: id,
      simpleStatus,
      address,
      primaryPhoto,
      price,
      surveyResponse,
    };
  });
};

export const useMarketingTimelineSelector = (propertyId) => {
  const property = usePropertySelector(propertyId);

  return property?.timeline || {};
};

export const useTimelineHVSelector = (propertyId) => {
  const timeline = useMarketingTimelineSelector(propertyId);

  return timeline[0]?.items?.find((item) => item?.name === 'home_visit') || {};
};
