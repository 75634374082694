import { useDispatch } from 'react-redux';

import { SET_ACTIVE_MODE, SET_ACTIVE_PROPERTY } from 'reducers/misc';
import { useMiscSelector } from 'selectors/misc';
import { STORAGE_KEY } from 'constants/mode';

const useModeControl = () => {
  const dispatch = useDispatch();
  const { activeMode, activeProperty } = useMiscSelector();

  const setActiveMode = (mode) => {
    const savedMode = localStorage.getItem(STORAGE_KEY.ACTIVE_MODE);
    if (activeMode !== mode || savedMode !== mode) {
      dispatch({
        type: SET_ACTIVE_MODE,
        payload: mode,
      });
      localStorage.setItem(STORAGE_KEY.ACTIVE_MODE, mode);
    }
  };

  const setActiveProperty = (id) => {
    if (activeProperty !== id) {
      dispatch({
        type: SET_ACTIVE_PROPERTY,
        payload: id,
      });
      localStorage.setItem(STORAGE_KEY.ACTIVE_PROPERTY, id);
    }
  };

  return {
    setActiveMode,
    setActiveProperty,
  };
};

export default useModeControl;
