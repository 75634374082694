import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNotifications as useNotificationsHook,
  useSyncState,
  SUCCESS,
  NOTIFICATION_STATUS,
} from '@strikelabs/vega';
import { useHistory } from 'react-router-dom';

import useGetHook from 'hooks/utils/useGetHook';
import useSendHook from 'hooks/utils/useSendHook';
import {
  RECEIVED_NOTIFICATIONS,
  UPDATE_NOTIFCATIONS_BADGE,
} from 'reducers/notifications';
import { useNotifiationsListSelector } from 'selectors/notifications';

const { BASE_API_URL } = process.env;

const useNotifications = ({ autoGetBadge, autoGetNotifications }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const notificationsList = useNotifiationsListSelector();
  const state = useNotificationsHook({
    useGetHook,
    useSendHook,
    baseUrl: BASE_API_URL,
    autoGetBadge,
    autoGetNotifications,
  });
  const {
    fetchNotifications,
    fetchNotificationsState,
    notifications,
    setNotificationStatus,
    setNotificationStatusState,
    fetchBadgeState,
  } = state;

  // Update badge
  useSyncState(() => {
    dispatch({
      type: UPDATE_NOTIFCATIONS_BADGE,
      payload: fetchBadgeState.data,
    });
  }, fetchBadgeState.status === SUCCESS);

  // Update state when notifications gets fetched by default
  useSyncState(() => {
    dispatch({
      type: RECEIVED_NOTIFICATIONS,
      payload: fetchNotificationsState.data?._embedded?.items || [],
    });
  }, fetchNotificationsState.status === SUCCESS);

  // Update state when pagination is used
  useEffect(() => {
    if (notifications.length) {
      if (notifications.length !== notificationsList.length) {
        dispatch({
          type: RECEIVED_NOTIFICATIONS,
          payload: notifications,
        });
      }
    }
  }, [notifications.length]);

  // Refetch notifications and update bage when notification status changes
  useEffect(() => {
    if (setNotificationStatusState.status === SUCCESS) {
      fetchNotifications();
      dispatch({
        type: UPDATE_NOTIFCATIONS_BADGE,
        payload: setNotificationStatusState.data,
      });
    }
  }, [setNotificationStatusState.status]);

  const onNotificationPress = (data) => {
    const { notification_id, status } = data;

    if (status !== NOTIFICATION_STATUS.READ) {
      // When someone click on notification we want to change status to read.
      setNotificationStatus([notification_id], NOTIFICATION_STATUS.READ);
    }

    // Navigate to given URL
    if (data?.cta_url) {
      // Handle old URLs
      // Old photos and epc URL
      if (/^property\/\d+\/my-advert/.test(data.cta_url)) {
        push(`/selling/${data.property_id}/details`);
        return;
      }
      // Old property URL
      if (/^property\/\d+$/.test(data.cta_url)) {
        push(`/selling/${data.property_id}`);
        return;
      }
      // Old viewings URLs
      if (/^viewings\/\d+/.test(data.cta_url)) {
        const viewingId = data.cta_url.match(/\d+/);
        push(`/selling/${data.property_id}/viewings/${viewingId[0]}`);
        return;
      }

      push(`/${data.cta_url}`);
    } else {
      push('/');
    }
  };

  return { ...state, notifications: notificationsList, onNotificationPress };
};

export default useNotifications;

export const useMarkNotificationsAsSeen = (notifications) => {
  const { setNotificationStatus } = useNotifications({
    autoGetBadge: false,
    autoGetNotifications: false,
  });
  // As soon as we render the page mark all sent notifications to seen
  useEffect(() => {
    if (notifications.length !== 0) {
      // Find all notifcations that need to be marked as seen
      const notSeenNotifications = notifications.reduce((acc, item) => {
        if (item.status !== NOTIFICATION_STATUS.SENT) {
          return acc;
        }
        return [...acc, parseInt(item.notification_id, 10)];
      }, []);

      if (notSeenNotifications.length !== 0) {
        // By default it will mark it as seen
        setNotificationStatus(notSeenNotifications);
      }
    }
  }, [notifications.length]);
};
