import { initializeApp } from 'firebase/app';
import {
  isSupported,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { useEffect, useState } from 'react';
import platform from 'platform';
import { useAuth } from '@strikelabs/vega';
import { useDispatch } from 'react-redux';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Bowser from 'bowser';

import useSendHook from 'hooks/utils/useSendHook';
import { RECEIVED_NOTIFICATION } from 'reducers/notifications';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

/*
 * Existing firebase issue:
 * A problem occurred while unsubscribing the user from FCM: FirebaseError: Messaging:
 * A problem occurred while unsubscribing the user from FCM: Requested entity was not found.
 * (messaging/token-unsubscribe-failed). (messaging/token-unsubscribe-failed).
 *
 * https://github.com/firebase/firebase-js-sdk/issues/2364
 * This doesn't seem to impact functionality and it's an edge case.
 */

const fpPromise = FingerprintJS.load();
const FIREBASE_TOKEN_KEY = 'FIREBASE_TOKEN_KEY';

const useSWReg = () => {
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();
  const { loggedIn } = useAuth();
  const [, registerToken] = useSendHook({
    url: `${process.env.BASE_API_URL}/users/notification-tokens`,
    authRequest: true,
  });

  const getFingerprint = async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    return visitorId;
  };

  const registerFirebaseToken = async (token) => {
    const device_id = await getFingerprint();
    if (device_id) {
      registerToken({
        data: {
          token,
          device_id,
          device_name: platform.description,
        },
      });
      window.localStorage.setItem(FIREBASE_TOKEN_KEY, token);
    }
  };

  /*
   * Check if notifications can be enabled
   */
  useEffect(async () => {
    // FCM is not well supported on some of the browsers,
    // we want to disable it if it matches the condition.
    const browser = Bowser.getParser(window.navigator.userAgent);
    const unsupported = browser.satisfies({
      uc: '<12',
      samsung_internet: '<13',
      mobile: {
        chrome: '<77',
      },
      Miui: '<13',
      amazon_silk: '<74',
    });

    console.log('unsupported', unsupported);

    const supportedFirebase = await isSupported();
    const supportedSw = 'serviceWorker' in navigator;
    const supportedBrowser = !!(
      (
        window.Notification /* W3C Specification */ ||
        window.webkitNotifications /* old WebKit Browsers */ ||
        navigator.mozNotification
      ) /* Firefox for Android and Firefox OS */
    );

    if (
      !unsupported &&
      loggedIn &&
      supportedFirebase &&
      supportedBrowser &&
      supportedSw
    ) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [loggedIn]);

  useEffect(() => {
    const registerSW = async () => {
      const firebaseApp = initializeApp(firebaseConfig);

      let registration;
      try {
        registration = await navigator.serviceWorker.register(
          `${window.location.origin}/firebase-messaging-sw.js`
        );
      } catch (error) {
        const registrationString = JSON.stringify(registration);
        throw new Error(
          `Error: ${error} || Registration: ${registrationString}`
        );
      }

      if (!registration) {
        return;
      }

      const messaging = getMessaging(firebaseApp);
      try {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
          const currentToken = window.localStorage.getItem(FIREBASE_TOKEN_KEY);
          const token = await getToken(messaging, {
            vapidKey: process.env.FIREBASE_VAPID,
          });

          if (currentToken !== token) {
            registerFirebaseToken(token);
          }
        }
      } catch (err) {
        if (err.code === 'messaging/permission-blocked') {
          console.warn('Please Unblock Notification Request Manually: ', err);
        } else {
          console.warn('getToken err: ', err);
        }
      }

      onMessage(messaging, ({ data }) => {
        // Update notifications list and the badge count
        dispatch({
          type: RECEIVED_NOTIFICATION,
          payload: data,
        });

        // This is the function that gets triggered when you receive a
        // push notification while you’re on the page. So you can
        // create a corresponding UI for you to have the push
        // notification handled.
        registration.showNotification('Strike.co.uk', {
          vibrate: [200],
          body: data.text,
          data,
        });
      });
    };

    if (enabled) {
      registerSW();
    }
  }, [enabled]);
};

export default useSWReg;
