import React, { memo } from 'react';
import PropTypes from 'prop-types';

import DesktopNavigation from './Desktop';
import MobileNavigation from './Mobile';
import { SELLING } from 'constants/mode';
import { useMiscSelector } from 'selectors/misc';
import { useUnreadMessagesCountSelector } from 'selectors/chat';

const menu = () => {
  const { activeMode, activeProperty } = useMiscSelector();
  const messagesBadge = useUnreadMessagesCountSelector();

  const selling = activeMode === SELLING;
  return [
    {
      title: 'Dashboard',
      icon: 'dashboardV2',
      iconWidth: '22',
      mobileIconWidth: '18px',
      mobileIconHeight: '20',
      route: selling ? `/selling/${activeProperty}` : `/buying`,
      selector: 'dashboard-link',
    },
    {
      title: 'Messages',
      icon: 'messageV3',
      iconWidth: '22',
      mobileIconWidth: '20',
      mobileIconHeight: '20',
      route: '/messages',
      selector: 'messages-link',
      badge: messagesBadge,
    },
    {
      title: 'Viewings',
      icon: 'viewingsV2',
      iconWidth: '24',
      mobileIconWidth: '25',
      mobileIconHeight: '20',
      route: selling
        ? `/selling/${activeProperty}/viewings`
        : `/buying/viewings`,
      selector: 'viewings-link',
    },
    {
      title: 'Offers',
      icon: 'offersV3',
      iconWidth: '24',
      mobileIconWidth: '22',
      mobileIconHeight: '20',
      route: selling ? `/selling/${activeProperty}/offers` : `/buying/offers`,
      selector: 'offers-link',
    },
    {
      title: 'Move Ready',
      icon: 'checkListV2',
      iconWidth: '22',
      mobileIconWidth: '18',
      mobileIconHeight: '20',
      route: '/move-ready',
      selector: 'move-ready-link',
    },
  ];
};

const Navigation = ({ mobile }) => {
  if (mobile) {
    return <MobileNavigation menu={menu()} />;
  }
  return <DesktopNavigation menu={menu()} />;
};

Navigation.defaultProps = {
  mobile: false,
};

Navigation.propTypes = {
  mobile: PropTypes.bool,
};

export default memo(Navigation);
