import { PURGE } from 'constants/actions';
export const FETCH_RIGHTMOVE_STATS = 'FETCH_RIGHTMOVE_STATS';
export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RIGHTMOVE_STATS: {
      const { payload } = action;
      return payload ? payload : initialState;
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
