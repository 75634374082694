import { useEffect, useRef } from 'react';
import { useWindowFocus } from '@strikelabs/pax';
import { useIdle } from 'react-use';

const usePoll = (callback, delayInSeconds = 5) => {
  let savedCallback = useRef();
  // Delay defined in seconds
  const delay = delayInSeconds ? delayInSeconds * 1000 : null;
  const isInFocus = useWindowFocus();
  const isIdle = useIdle();
  // Make sure that user is active
  const isUserActive = !isIdle && isInFocus;

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (isUserActive && delay !== null) {
      const id = setInterval(tick, delay);

      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay, isUserActive]);
};

export default usePoll;
