import React, { useEffect } from 'react';
import { useAuth } from '@strikelabs/vega';
import { identifyUser } from '@strikelabs/sputnik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Placeholder from 'components/v2/Layout/Placeholder';
import { FETCHED_USER } from 'reducers/user';

const AccessProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loading, loggedIn, user } = useAuth();

  /*
   * When user is fetched we update our state
   * to make sure we don't have to fetch it again
   */
  useEffect(() => {
    if (user) {
      dispatch({
        type: FETCHED_USER,
        payload: user,
      });
      identifyUser(user.id);
    }
  }, [user]);

  /*
   * If user is returned we know that all access
   * tokens are valid and not expired. We then show the hun UI
   */
  if (user && loggedIn) {
    return children;
  }

  /*
   * Show loading UI we revalidate the access
   */
  if (loading) {
    return <Placeholder />;
  }

  /*
   * This should never be reached because loading
   * is true when user details are being fetched.
   *
   * We have this as a failsafe in case something goes wrong
   */
  return <Placeholder />;
};

AccessProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AccessProvider;
