import { Text } from '@strikelabs/luna';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import BadgeComponent from 'components/_common/Badge';

export const Nav = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -1px 0px #ededed;
`;

export const IconText = styled(Text)`
  font-size: 12px;
  padding-top: 0.5rem;
`;

export const IconContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.sm};
`;

export const MenuLinkInner = styled.div`
  position: relative;
  opacity: 0.4;
`;

export const MenuLink = styled(NavLink)`
  position: relative;
  text-decoration: none;
  display: block;
  text-align: center;

  &.active,
  &:hover {
    ${MenuLinkInner} {
      opacity: 1;
    }
    &::before {
      content: '';
      display: block;
      height: 5px;
      background: ${({ theme }) => theme.colors.primaryGreen};
    }
  }

  // not enough space to apply 20% width across a screen of less than 400px.
  @media (min-width: 400px) {
    width: 20%;
  }
`;

export const Badge = styled(BadgeComponent)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
`;
