import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Burger, Flex, Icon, useIsMedia } from '@strikelabs/luna';
import { identifier, getInitials } from '@strikelabs/pax';

import { useMiscSelector } from 'selectors/misc';
import { SELLING } from 'constants/mode';

import { useUserSelector } from 'selectors/user';
import Navigation from './Navigation';
import NotificationsBell from './NotificationBell';

import { Container, InitialsCircle, InitialsText, Draw } from './style';

const Header = () => {
  const { firstName, lastName } = useUserSelector();
  const { desktop } = useIsMedia();
  const { activeProperty, activeMode } = useMiscSelector();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (desktop) {
      setOpen(false);
    }
    return () => {
      setOpen(false);
    };
  }, [desktop]);

  const getHomeUrl = useMemo(() => {
    if (activeMode === SELLING) {
      return `/selling/${activeProperty}`;
    }

    return '/buying';
  }, [activeMode, activeProperty]);

  return (
    <div>
      <Container>
        <Flex
          maxWidth="1350px"
          alignItems="center"
          justifyContent="space-between"
          width={1}
        >
          <Box ml={1}>
            <Link aria-label="home" to={getHomeUrl}>
              <Icon width="94" type="strikeLogo" />
            </Link>
          </Box>
          {desktop && <Navigation />}
          <Box mr={1}>
            <Flex flexDirection="row" alignItems="center">
              <Link
                to="/profile"
                {...identifier({
                  name: 'profile-page-link',
                  requireId: false,
                })}
                style={{ textDecoration: 'none' }}
              >
                <InitialsCircle>
                  <InitialsText>
                    {getInitials({
                      firstName,
                      lastName,
                    })}
                  </InitialsText>
                </InitialsCircle>
              </Link>
              <Flex ml={1}>
                <NotificationsBell />
              </Flex>
              {!desktop && (
                <Flex ml={1}>
                  <Burger
                    aria-label="burger menu"
                    color="lightIcon"
                    height={21}
                    setOpen={setOpen}
                    open={open}
                  />
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      </Container>
      {open && (
        <Draw px={[1, 3]} py={1}>
          <Navigation mobile />
        </Draw>
      )}
    </div>
  );
};

export default Header;
