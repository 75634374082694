import React from 'react';
import { Flex, Box, useIsMedia, Icon } from '@strikelabs/luna';
import { StyledLink } from 'components/_common/style';
import { Container, Divider, SmallText, Links } from './style';

const Footer = () => {
  const { isMobile } = useIsMedia(true);

  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  return (
    <Container role="contentinfo">
      <Flex alignItems="center" textAlign="center" mb={5}>
        <Box mr={[2, 2, 5, 5]}>
          <Icon type="strikeLogo" width="67" theme="dark" />
        </Box>
        <Box mr={0}>
          <Icon type="estasAwardV2" width="110px" color="captionText" />
        </Box>
        <Box>
          <Icon type="goldWinner" width="110px" color="captionText" />
        </Box>
      </Flex>

      <Links mb={2}>
        <Flex justifyContent="center" flexWrap="wrap">
          <StyledLink
            to={`${process.env.MARKETING_SITE_URL}/legal/terms-of-use `}
            underline="underline"
          >
            <SmallText>Terms of use</SmallText>
          </StyledLink>
          <Divider />
          <StyledLink
            to={`${process.env.MARKETING_SITE_URL}/legal/cookie-policy`}
            underline="underline"
          >
            <SmallText>Cookie policy</SmallText>
          </StyledLink>
          <Divider />
          <StyledLink
            to={`${process.env.MARKETING_SITE_URL}/legal/privacy-policy`}
            underline="underline"
          >
            <SmallText>Privacy policy</SmallText>
          </StyledLink>
          {!isMobile && <Divider />}
          <StyledLink
            to={`${process.env.MARKETING_SITE_URL}/legal/complaints-procedure`}
            underline="underline"
          >
            <SmallText>Complaints procedure</SmallText>
          </StyledLink>
        </Flex>
      </Links>

      <Box mb={isMobile && 6}>
        <SmallText>Copyright &copy; Strike Limited {getYear()}</SmallText>
      </Box>
    </Container>
  );
};

export default Footer;
