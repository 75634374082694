import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { SUCCESS } from '@strikelabs/vega';

import useGetActivePropertyId from 'hooks/utils/useGetActivePropertyId';
import useModeControl from 'hooks/utils/useModeControl';
import useProperties from 'hooks/useProperties';
import { useMiscSelector } from 'selectors/misc';
import { usePropertiesArraySelector } from 'selectors/properties';
import { SELLING, BUYING } from 'constants/mode';
import Placeholder from 'components/v2/Layout/Placeholder';

const defaultLandingPath = '/buying';

const ModeProvider = ({ children }) => {
  const sellingProperties = usePropertiesArraySelector('selling');
  const buyingProperties = usePropertiesArraySelector('buying');
  const [propertiesFetched, setFetchedProperties] = useState(false);
  const { fetchPropertiesState } = useProperties({
    autoGet: !propertiesFetched,
  });
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const { activeMode } = useMiscSelector();
  const { setActiveProperty } = useModeControl();
  const { getPropertyId, validateId } = useGetActivePropertyId();
  const sellingPathRegex = new RegExp(/\/selling(\/)?([^/]+)?/);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }
  }, [pathname]);

  const getActivePropertyId = () => {
    const activeId = getPropertyId();
    const isIdValid = validateId(activeId);

    if (!activeId || !isIdValid) {
      if (sellingProperties.length) {
        // No need to validate since it's
        // coming from API
        return sellingProperties[0].id;
      } else {
        return null;
      }
    }

    if (isIdValid) {
      return activeId;
    }

    return null;
  };

  const handleSellingPath = (isRoot = false) => {
    const activeId = getActivePropertyId();

    if (!activeId) {
      return replace('/selling');
    }

    setActiveProperty(activeId);

    // If they land on root of the website
    // we take them to property dashboard page
    if (isRoot) {
      return replace(`/selling/${activeId}`);
    }

    // Validate activeId and update URL
    return replace(pathname.replace(sellingPathRegex, `/selling/${activeId}`));
  };

  useEffect(() => {
    if (fetchPropertiesState.status === SUCCESS) {
      setFetchedProperties(true);
    }
  }, [fetchPropertiesState.status]);

  /*
   * We want to make sure that people
   * are redirected to correct place
   * based on route they land on initially
   */
  useEffect(() => {
    if (propertiesFetched) {
      /*
       * Handle '/' pathname
       */
      if (
        pathname === '/' ||
        pathname.startsWith('/offers') ||
        pathname.startsWith('/viewings')
      ) {
        /*
         * Initial behaviour for new USER
         * or when someone logs in for the first time
         *
         * Default mode is null
         */
        if (activeMode === null) {
          if (sellingProperties.length) {
            return handleSellingPath(true);
          }

          if (buyingProperties.length) {
            return replace('/buying');
          }

          // Default to selling if user
          // never visited before and
          // have no properties
          return replace(defaultLandingPath);
        }

        if (activeMode === SELLING) {
          return handleSellingPath(true);
        }

        if (activeMode === BUYING) {
          return replace('/buying');
        }

        // If mode value not covered
        // just redirect to selling
        return replace(defaultLandingPath);
      }

      /*
       * Handle '/selling' pathname
       */
      if (sellingPathRegex.test(pathname)) {
        return handleSellingPath();
      }
    }
  }, [propertiesFetched]);

  if (!propertiesFetched) {
    return <Placeholder />;
  }

  return children;
};

ModeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default ModeProvider;
