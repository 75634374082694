import { merge, isEmpty } from 'lodash';
import {
  getSimplePropertyStatus,
  SIMPLIFIED_PROPERTY_STATUS,
  LISTING_STATUS,
} from '@strikelabs/vega';

const MARKETING_STATUS = {
  AVAILABLE: 'available',
  SOLD: 'sold',
  SOLD_STC: 'sold_stc',
  SOLD_STCM: 'sold_stcm',
  UNDER_OFFER: 'under offer',
  UNKNOWN: 'unknown',
};

const getSimpleStatusV2 = ({ marketingStatus, listingStatus }) => {
  const draft = listingStatus === LISTING_STATUS.LIVE_UNDER_REVIEW;

  if (marketingStatus === MARKETING_STATUS.SOLD) {
    return {
      draft,
      status: SIMPLIFIED_PROPERTY_STATUS.SOLD,
    };
  }

  if (marketingStatus === MARKETING_STATUS.UNDER_OFFER) {
    return {
      draft,
      status: SIMPLIFIED_PROPERTY_STATUS.UNDER_OFFER,
    };
  }

  if (marketingStatus === MARKETING_STATUS.SOLD_STC) {
    return {
      draft,
      status: SIMPLIFIED_PROPERTY_STATUS.SSTC,
    };
  }

  if (
    listingStatus === LISTING_STATUS.NOT_LIVE ||
    listingStatus === LISTING_STATUS.GOING_LIVE_SOON
  ) {
    return {
      draft,
      status: SIMPLIFIED_PROPERTY_STATUS.NOT_LIVE,
    };
  }

  return {
    draft,
    status: SIMPLIFIED_PROPERTY_STATUS.LIVE,
  };
};

export const transformPropertiesPayload = (data, initialData) => {
  if (isEmpty(data?.properties)) {
    return {};
  }

  const newData = data.properties.reduce((acc, item) => {
    const { listingStatus, marketingStatus, price } = item;
    acc[item.id] = {
      simpleStatus: getSimpleStatusV2({ listingStatus, marketingStatus }),
      listingStatus,
      timeline: [],
      listingLinks: {},
      revisions: {
        current: {
          filesObject: {},
          price: price || null,
          ...item.propertyDetails,
        },
        draft: {
          filesObject: {},
        },
      },
      primaryPhoto: item?.propertyDetails?.primaryPhoto || null,
      address: item.address || null,
      applicationStatus: item.applicationStatus,
      marketingStatus: item.marketingStatus,
      files: [],
      id: item.id,
      listingType: item.listingType,
      numberOfBeds: item.propertyDetails.numberOfBeds || null,
      price: price || null,
      propertyType: item.propertyDetails.propertyType || null,
    };

    return acc;
  }, {});

  return merge({}, newData, initialData);
};

export const formatPropertyDetails = ({ payload, isDraft }) => {
  const {
    listingStatus,
    marketingStatus,
    price,
    questionnaire,
    filesObject,
    allowSimultaneousViewings,
    ...rest
  } = payload;
  const simpleStatus = getSimplePropertyStatus({
    listingStatus,
    marketingStatus,
  });

  const isNotLive = simpleStatus.status === SIMPLIFIED_PROPERTY_STATUS.NOT_LIVE;
  const KEY = isDraft ? 'draft' : 'current';

  /*
   * Here we will add all common details that will not be affected by the draft details and will be used
   * in various places on the app.
   *
   * Instead of adding marketingStatus and listingStatus we use them to create simpleStatus
   * (currently there is a lot of inconsistency around statuses on the back-end, thats why we use getSimplePropertyStatus)
   */
  const baseObject = {
    simpleStatus,
    listingStatus,
    ...rest,
  };

  /*
   *  Here all editable details are combined to revisions based on the API that was used to fetch it.
   */
  const revisionObject = {
    price,
    filesObject,
    ...questionnaire,
  };

  /*
   * If property is not live we know that property details doesn't need to be moderated,
   * because of that draft and current revisions will be the same
   */
  if (isNotLive) {
    return {
      ...baseObject,
      revisions: {
        current: revisionObject,
        draft: revisionObject,
      },
    };
  }

  /*
   * If property is live we want to separate details in to different revisions.
   * If draft was fetched we move that to draft, otherwise it goes under current.
   */
  return {
    ...baseObject,
    allowSimultaneousViewings,
    revisions: {
      [KEY]: revisionObject,
    },
  };
};

export const getPropertyMode = (isSelling, state) => {
  return {
    label: isSelling ? 'selling' : 'buying',
    newState: isSelling ? state.selling : state.buying,
  };
};
