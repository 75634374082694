import React from 'react';
import styled from 'styled-components';
import { Box, Text, Button } from '@strikelabs/luna';
import { Link as RouterLink } from 'react-router-dom';
import { rem } from 'polished';

export const Hr = styled(Box)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme, bgColor }) =>
    bgColor && theme.colors[bgColor]
      ? theme.colors[bgColor]
      : theme.colors.ultraLight};
`;

export const Separator = styled.div`
  width: 1px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.medium};
  margin: 3px ${({ theme }) => theme.spacing.xs};
`;
export const NavLink = styled(RouterLink)`
  text-decoration: none;
`;
export const LinkText = styled(Text).attrs({
  as: 'p',
})`
  display: inline-block;
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: underline;
  cursor: pointer;
  text-align: ${({ center }) => (center ? 'center' : null)};
`;

export const StyledLink = styled(({ to, children, ...rest }) =>
  to ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  )
)`
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

/*Container for Review */
export const Container = styled.div`
  background-color: #000;
  border-radius: ${rem(5)};
  ${({ theme }) => theme.media.giant} {
    width: ${({ reviews }) => (reviews ? '120%' : '100%')};
  }
`;

export const ViewingsOffersImage = styled.img`
  max-height: 320px;
  max-width: 100%;
`;

export const CenterText = styled(Text)`
  text-align: center;
`;

export const Link = styled.a`
  color: inherit;
`;

export const BaseLink = styled(Link)`
  text-decoration: none;
`;

export const BasicLink = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`;

export const LinkButton = styled(BasicLink).attrs({
  as: 'p',
  styleType: 'body',
})`
  text-align: center;
  display: block;
`;

export const RecessiveButton = styled(Button).attrs({
  styleType: 'link',
})`
  span {
    color: ${({ theme }) => theme.colors.mediumDark};
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const RadioButtonContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.medium};
  border-radius: 4px;
  position: relative;
  ${({ active }) =>
    active &&
    `
        background-color: black;
    `}
`;

export const BlackBoxContainer = styled(Box)`
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  height: 20px;
  width: 22px;
  background-color: black;
  left: 1rem;
  display: none;
  ${({ active }) => active && 'display: block;'}
`;

export const RadioContainer = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
`;

export const StyledRadio = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primaryGreen};
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledText = styled(Text)`
  ${({ active }) =>
    active &&
    `
        color:white;
    `}
`;
