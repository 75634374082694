import { Flex, Button, Box, Text, Icon } from '@strikelabs/luna';
import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink } from './style';

const links = [
  {
    text: 'Why Strike?',
    link: `${process.env.MARKETING_SITE_URL}/why-strike`,
    chevron: true,
  },
  {
    text: 'Find a property',
    link: `${process.env.MARKETING_SITE_URL}/property-for-sale`,
    chevron: true,
    ml: 3,
  },
  {
    text: 'Book a valuation',
    link: `${process.env.MARKETING_SITE_URL}/book-valuation`,
    chevron: false,
    button: true,
    ml: 3,
  },
];

const Navigation = ({ mobile }) => {
  if (mobile) {
    return (
      <Flex flexDirection="column" justifyContent="space-between" height="100%">
        {links.map(({ link, text, chevron, button }, i) => (
          <StyledLink key={link} to={link}>
            <Flex
              mt={i !== 0 && 2}
              justifyContent="space-between"
              alignItems="center"
            >
              {button ? (
                <Button>{text}</Button>
              ) : (
                <Text as="p" color="white">
                  {text}
                </Text>
              )}
              {chevron && (
                <Icon type="chevronRight" width="15px" color="lightIcon" />
              )}
            </Flex>
          </StyledLink>
        ))}
      </Flex>
    );
  }
  return (
    <Flex alignItems="center">
      {links.map(({ link, text, button, ml }) => (
        <Box ml={ml} key={link}>
          <StyledLink to={link}>
            {button ? (
              <Button>{text}</Button>
            ) : (
              <Text color="white">{text}</Text>
            )}
          </StyledLink>
        </Box>
      ))}
    </Flex>
  );
};

export default Navigation;

Navigation.defaultProps = {
  mobile: false,
};

Navigation.propTypes = {
  mobile: PropTypes.bool,
};
