import styled from 'styled-components';

export const Container = styled.div`
  padding-left: ${({ theme }) => theme.spacing.sm};
  padding-right: ${({ theme }) => theme.spacing.sm};
  width: 100%;
  position: relative;

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.rg};
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Inner = styled.div`
  ${({ theme }) => theme.media.desktop} {
    position: sticky;
    top: ${({ theme }) => theme.spacing.rg};
  }
`;
