import styled from 'styled-components';

export const Header = styled.div`
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 65px;

  ${({ theme }) => theme.media.tablet} {
    height: 82px;
  }
`;
