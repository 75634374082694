import { PURGE } from 'constants/actions';
import { STORAGE_KEY } from 'constants/mode';

export const SET_ACTIVE_MODE = 'SET_ACTIVE_MODE';
export const SET_ACTIVE_PROPERTY = 'SET_ACTIVE_PROPERTY';

export const initialState = {
  activeProperty:
    Number(localStorage.getItem(STORAGE_KEY.ACTIVE_PROPERTY)) || null,
  activeMode: localStorage.getItem(STORAGE_KEY.ACTIVE_MODE) || null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODE:
      return {
        ...state,
        activeMode: action.payload,
      };

    case SET_ACTIVE_PROPERTY:
      return {
        ...state,
        activeProperty: action.payload,
      };

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
