import { PURGE } from 'constants/actions';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { getDateObject } from '@strikelabs/pax';

export const RECEIVED_CHAT_LIST = 'RECEIVED_CHAT_LIST';
export const RECEIVED_CHAT_MESSAGES = 'RECEIVED_CHAT_MESSAGES';
export const RESET_UNREAD_MESSAGES_COUNT = 'RESET_UNREAD_MESSAGES_COUNT';

export const initialState = {
  list: [],
  messages: {},
};

const formatChatsList = ({
  list,
  messages,
  targetId,
  sourceId,
  updateForMessage,
}) => {
  const newList = [...list];

  const formatTimestamp = (time) => {
    const parsed = getDateObject(time);
    return format(parsed, "yyyy-MM-dd'T'HH:mm:ss");
  };

  const formatMsg = (senderId, message) => {
    const fromMe = Number(sourceId) === Number(senderId);
    return `${fromMe ? 'You: ' : ''}${message || ''}`;
  };

  // Perform update to contacts list when new message is received
  if (updateForMessage) {
    const lastMessage = messages[messages?.length - 1] || {};
    const currMsgIndex = newList.findIndex(
      (chat) => Number(chat.id) === Number(targetId)
    );

    newList[currMsgIndex] = {
      ...newList[currMsgIndex],
      message: formatMsg(lastMessage?.from?.id, lastMessage.message),
      timestamp: formatTimestamp(lastMessage?.timestamp),
    };
  }

  // Perform update to contacts list when new list is received
  if (!updateForMessage) {
    for (let index = 0; index < newList.length; index++) {
      const chat = newList[index];

      newList[index] = {
        ...chat,
        message: formatMsg(chat.last_message_sender_id, chat.last_message),
        timestamp: formatTimestamp(chat.message_last_datetime),
      };
    }
  }

  // Order by date
  return orderBy(newList, 'timestamp', 'desc');
};

const resetUnreadMessages = (sourceId, chats) => {
  const newChats = chats.map((chat) => {
    if (chat.id === sourceId) {
      return {
        ...chat,
        unread_message_count: null,
      };
    }
    return chat;
  });

  return newChats;
};

const reducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case RECEIVED_CHAT_LIST:
      return {
        ...state,
        list: formatChatsList({
          list: payload.data,
          messages: state.messages,
          targetId: payload.targetId,
          sourceId: payload.userId,
          updateForMessage: false,
        }),
      };

    case RECEIVED_CHAT_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          [payload.targetId]: {
            loaded: true,
            log: payload.messages,
          },
        },
        list: formatChatsList({
          list: state.list,
          messages: payload.messages,
          targetId: payload.targetId,
          sourceId: payload.sourceId,
          updateForMessage: true,
        }),
      };

    case RESET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        list: resetUnreadMessages(payload.sourceId, state.list),
      };

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
