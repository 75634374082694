import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StrikeToPBBanner, useIsMedia } from '@strikelabs/luna';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouteMatch } from 'react-router-dom';

import useDocumentScrollThrottled from 'hooks/useDocumentScrollThrottled';

import Sidebar from '../_elements/Sidebar';
import Header from '../_elements/Header';
import Footer from '../_elements/Footer';
import Navigation from '../_elements/Navigation';

import { GroupTop, GroupBottom, Wrapper, Body } from './style';

/*
 * This components provides base layout which enables
 * handles stickly mobile navigation behaviour
 */
const Base = ({ children }) => {
  const { desktop } = useIsMedia();

  /*
   * Certain pages on mobile need to hide navigation completely
   */
  const blacklist = ['/messages/chat']; // Accepts array of paths to be blacklisted
  const isNavBlackListed = useRouteMatch(blacklist)?.isExact;
  const isFooterHidden = useRouteMatch({ path: '/messages', strict: false });
  /*
   * Control sticky mobile navigation scroll behaviour
   */
  const [shouldHideMobileNav, setShouldHideMobileNav] = useState(false);
  const timer = useRef(null);
  const MINIMUM_SCROLL_BOTTOM_NAV = 50;
  const TIMEOUT_DELAY = 200;
  // Get height of the BottomGroup element, used for animations
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const isMobileNavVisible = !isNavBlackListed && !desktop;

  /*
   * Control animated mobile nav
   */
  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;

    if (!desktop) {
      const isScrolledDown = previousScrollTop < currentScrollTop;
      const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL_BOTTOM_NAV;
      const isBottomReached =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - MINIMUM_SCROLL_BOTTOM_NAV;

      if (isBottomReached) {
        clearTimeout(timer.current);
        setShouldHideMobileNav(false);
      } else {
        timer.current = setTimeout(() => {
          setShouldHideMobileNav(isScrolledDown && isMinimumScrolled);
        }, TIMEOUT_DELAY);
      }
    }
  });

  useEffect(() => {
    if (ref) {
      setHeight(ref?.current?.clientHeight);
    }
  }, [isMobileNavVisible, ref]);

  return (
    <>
      <GroupTop margin={isMobileNavVisible ? height : 0}>
        <Header />
        <StrikeToPBBanner
          whiteColor={true}
          launchNewTab={true}
          url={`${process.env.MARKETING_SITE_URL}/latest-news/moving-to-purplebricks`}
        />
        <Wrapper>
          <Body>
            {desktop && (
              <Sidebar>
                <Navigation />
              </Sidebar>
            )}
            {children}
          </Body>
        </Wrapper>
        {!isFooterHidden && <Footer />}
      </GroupTop>
      <GroupBottom ref={ref}>
        <AnimatePresence>
          {isMobileNavVisible && !shouldHideMobileNav && (
            <motion.div
              initial={{ opacity: 0, translateY: height }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: height }}
              transition={{ duration: 0.3 }}
            >
              <Navigation mobile />
            </motion.div>
          )}
        </AnimatePresence>
      </GroupBottom>
    </>
  );
};

Base.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Base;
