import { useSelector } from 'react-redux';

export const useOrdersSelector = () => {
  const orders = useSelector((state) => state?.orders);

  return orders;
};

export const usePropertyOrdersSelector = (propertyId) => {
  const orders = useOrdersSelector();

  return orders.filter(
    (order) => Number(order.propertyId) === Number(propertyId)
  );
};
