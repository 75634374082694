import { arrayToObject, keyObject } from '@strikelabs/pax';
import { PURGE } from 'constants/actions';

export const initialState = {
  loaded: false,
  list: {},
};
export const FETCHED_OFFERS = 'FETCHED_OFFERS';
export const FETCHED_OFFER = 'FETCHED_OFFER';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_OFFERS: {
      // When we fetch the offers list it overrides any existing data that we already have.
      // This will merge any exsisting data with new data.
      const mergeExisting = action.payload.map((offer) => ({
        ...(state.list[offer?.id] && state.list[offer.id]),
        ...offer,
      }));

      return {
        loaded: true,
        list: arrayToObject(mergeExisting),
      };
    }

    // When individual offer gets fetched merge that to the list of offers
    case FETCHED_OFFER: {
      return {
        ...state,
        list: Object.assign(state.list, keyObject(action.payload)),
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
