import React, { useMemo } from 'react';
import { Text, Box, Flex } from '@strikelabs/luna';
import { identifier } from '@strikelabs/pax';

import useNotifications, {
  useMarkNotificationsAsSeen,
} from 'hooks/useNotifications';
import Notification from 'components/Notification';
import Placeholder from './Placeholder';

import { Outer, Container, ViewAll } from './style';

const Dropdown = () => {
  const { notifications, onNotificationPress, fetchNotificationsState } =
    useNotifications({
      autoGetNotifications: true,
      autoGetBadge: true,
    });
  const visibleNotifications = notifications.slice(0, 3);
  useMarkNotificationsAsSeen(visibleNotifications);
  const renderNotifications = useMemo(() => {
    if (!visibleNotifications.length) {
      return (
        <Box mx={2}>
          <Text styleType="caption">No notifications</Text>
        </Box>
      );
    }
    return visibleNotifications.map((item, i) => (
      <Notification
        {...identifier({ name: `notification-item-${i}`, requireId: false })}
        onClick={() => onNotificationPress(item)}
        key={item.timestamp}
        title={item.text}
        timestamp={item.timestamp}
        status={item.status}
      />
    ));
  }, [notifications]);

  return (
    <Outer>
      <Container>
        <Box mx={2}>
          <Text as="h3" mb={1} id="property-list-title">
            Notifications
          </Text>
        </Box>
        {fetchNotificationsState.loading && !visibleNotifications.length ? (
          <Box mx={1}>
            <Placeholder />
          </Box>
        ) : (
          <>
            <div>{renderNotifications}</div>
            {!!visibleNotifications.length && (
              <Flex justifyContent="flex-end">
                <Box mt={1} mx={2}>
                  <ViewAll
                    {...identifier({
                      name: 'view-all-button',
                      requireId: false,
                    })}
                    to="/notifications"
                  >
                    View all
                  </ViewAll>
                </Box>
              </Flex>
            )}
          </>
        )}
      </Container>
    </Outer>
  );
};

export default Dropdown;
