import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './style';

const Badge = ({ children, background, color, small, ...props }) => {
  if (!children) {
    return null;
  }

  return (
    <Container background={background} small={small} color={color} {...props}>
      <span>{children}</span>
    </Container>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.bool,
};

Badge.defaultProps = {
  children: null,
  background: 'accentAlternate',
  color: 'darkMenuHighlight',
  small: false,
};

export default Badge;
