import { PURGE } from 'constants/actions';

export const initialState = {
  id: null,
  name: null,
  surname: null,
  occupation: null,
  personas: [],
  isReferredFS: null,
};
export const FETCHED_USER = 'FETCHED_USER';

// always use firt in array as it will be the latest
// financial poisition information

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_USER:
      return action.payload;

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
