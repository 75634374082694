import { Box } from '@strikelabs/luna';
import styled from 'styled-components';

export const Container = styled.header`
  background-color: black;
  color: white;
  padding: ${({ theme }) => `${theme.atomSpacing.md} 0`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InitialsCircle = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: ${({ theme }) => theme.colors.ultraLight};
  padding: ${({ theme }) => theme.atomSpacing.sm};
  border-radius: 100%;
  width: 30px;
  height: 30px;
  transition: all 0.1s ease-in-out;
  color: ${({ theme }) => theme.colors.mediumDark};
  border: 2px solid ${({ theme }) => theme.colors.light};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const InitialsText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: inherit;
`;

export const Draw = styled(Box)`
  position: absolute;
  background: black;
  width: 100vw;
  z-index: 999;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;
