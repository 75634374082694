import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Outer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.rg};
  position: absolute;
  right: -33px;
  width: 375px;
  z-index: 9999;
`;

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.ultraLight};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.rg} 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }

  &:before {
    right: 25px;
    border: 16px solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.ultraLight};
  }

  &:after {
    right: 26px;
    border: 15px solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ViewAll = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mediumDark};
`;
