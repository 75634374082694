import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import useChatList from 'hooks/useChatList';
import useOrders from 'hooks/useOrders';
import usePoll from 'hooks/utils/usePoll';

const AppLoadedProvider = ({ children }) => {
  useOrders(); // Fetch orders once when app is loaded
  const isMessagesRoute = useRouteMatch('/messages');

  // Only load initially if it's not messages route
  const [, fetchChatLog] = useChatList({
    autoGet: !isMessagesRoute,
  });

  /*
   * Subscribe to chat log when app is first loaded
   *
   * Refetch chat log every 300sec / 5 min
   * this will update unread messages badge
   */
  usePoll(fetchChatLog, 300);

  return children;
};

AppLoadedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLoadedProvider;
