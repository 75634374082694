import { PURGE } from 'constants/actions';

export const RECEIVED_IMAGES = 'RECEIVED_IMAGES';
export const RECEIVED_FLOORPLANS = 'RECEIVED_FLOORPLANS';
export const RECEIVED_FLOORPLANS_SKETCH = 'RECEIVED_FLOORPLANS_SKETCH';
export const RECEIVED_EPC = 'RECEIVED_EPC';

import { PHOTOS, FLOORPLAN, EPC, FLOORPLAN_SKETCH } from 'constants/files';

export const initialState = {
  [PHOTOS]: {},
  [FLOORPLAN]: {},
  [FLOORPLAN_SKETCH]: {},
  [EPC]: {},
};

const addFiles = ({ propertyId, files }) => {
  return {
    [propertyId]: files,
  };
};

const timeline = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_IMAGES:
      return {
        ...state,
        [PHOTOS]: {
          ...state[PHOTOS],
          ...addFiles(action.payload),
        },
      };

    case RECEIVED_FLOORPLANS:
      return {
        ...state,
        [FLOORPLAN]: {
          ...state[FLOORPLAN],
          ...addFiles(action.payload),
        },
      };

    case RECEIVED_FLOORPLANS_SKETCH:
      return {
        ...state,
        [FLOORPLAN_SKETCH]: {
          ...state[FLOORPLAN_SKETCH],
          ...addFiles(action.payload),
        },
      };

    case RECEIVED_EPC:
      return {
        ...state,
        [EPC]: {
          ...state[EPC],
          ...addFiles(action.payload),
        },
      };

    case PURGE:
      return initialState;

    default:
      return state;
  }
};

export default timeline;
