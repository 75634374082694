import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';

export const AutoRefreshingRouter = ({ children }) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShouldRefresh(true), 1000 * 60 * 60);
    return () => clearTimeout(id);
  }, []);

  return <BrowserRouter forceRefresh={shouldRefresh}>{children}</BrowserRouter>;
};

AutoRefreshingRouter.propTypes = {
  children: PropTypes.node.isRequired,
};
