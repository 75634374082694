import styled from 'styled-components';
import { Flex, Box, Text } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.rg};
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  flex-wrap: wrap;
`;

export const Divider = styled.span`
  &:after {
    content: '|';
    padding: 0 ${rem(5)};
    color: ${({ theme }) => theme.colors.mediumDark};
  }
  ${({ theme }) => theme.media.smallTablet} {
    margin-top: ${rem(5)};
  }
`;
export const Links = styled(Box)`
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const SmallText = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumDark};
`;
