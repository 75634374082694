import styled from 'styled-components';
import React from 'react';

export const StyledLink = styled(({ to, children, ...rest }) =>
  to ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  )
)`
  text-decoration: none;
`;
